<template>
  <div class="container-fluid">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col ml-n2">
                <h4 class="mb-1">
                  <a
                    >{{ $t('partnersDeposits.show.salesPayment') }}
                    <strong> {{ item.code }}</strong></a
                  >
                </h4>
              </div>
              <div class="col-auto">
                <router-link
                  :to="path + '/' + item.id + '/edit'"
                  class="btn btn-sm btn-primary"
                  :title="$t('partnersDeposits.show.edit')"
                >
                  <i class="fas fa-edit"></i>
                </router-link>
                <a
                  @click="print(item.id)"
                  class="btn btn-sm btn-primary"
                  :title="$t('partnersDeposits.show.print')"
                >
                  <i class="fas fa-print"></i>
                </a>
                <a
                  :href="$linkGnirator('/salespayments/' + item.id + '/pdf')"
                  download
                  class="btn btn-sm btn-primary"
                  :title="$t('partnersDeposits.show.DownloadPDF')"
                >
                  <i class="fas fa-download"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-credit-card-front"></i>
              {{ $t('partnersDeposits.show.PaymentDetails') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <table class="table table-sm table-nowrap customed-table-small">
                  <tbody>
                    <tr>
                      <th scope="row">
                        {{ $t('partnersDeposits.show.PaymentNumber') }}
                      </th>
                      <td>{{ item.code }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ $t('partnersDeposits.show.paymentDate') }}
                      </th>
                      <td>{{ $dateFormat(item.date) }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ $t('partnersDeposits.show.Administrator') }}
                      </th>
                      <td>{{ item.user ? item.user.name : '--' }}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ $t('partnersDeposits.show.referenceNumber') }}
                      </th>
                      <td>
                        {{ item.refrance != null ? item.refrance : '--' }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ $t('partnersDeposits.show.ReceiveFrom') }}
                      </th>
                      <td>
                        <router-link :to="'/partners/' + item.partner.id">{{
                          item.partner.name
                        }}</router-link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ $t('partnersDeposits.show.PaymentMethod') }}
                      </th>
                      <td scope="row">
                        {{ item.paymentMethod != null ? item.paymentMethod.name : '--' }}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        {{ $t('partnersDeposits.show.Treasury') }}
                      </th>
                      <td>
                        <router-link :to="'/safes/' + item.safe.id" v-if="item.safe">{{
                          item.safe.name
                        }}</router-link>
                        {{ item.safe ? '' : '--' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-3">
                <div class="price-show">
                  <div class="price-content">
                    <p>{{ $t('partnersDeposits.show.ReceivedAmount') }}</p>
                    <h1>{{ $moneyFormat(item.cost) }}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-user-alt"></i>
              {{ $t('partnersDeposits.show.PayFromThe') }}
            </h4>
          </div>
          <div class="card-body">
            <table class="table table-sm table-nowrap">
              <thead>
                <tr>
                  <th scope="col">
                    {{ $t('partnersDeposits.show.customerName') }}
                  </th>
                  <th scope="col">{{ $t('partnersDeposits.show.receivedAmount') }}</th>
                  <th scope="col">{{ $t('partnersDeposits.show.previousBalance') }}</th>
                  <th scope="col">{{ $t('partnersDeposits.show.totalBalance') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <router-link :to="'/partners/' + item.partner.id">{{
                      item.partner.name
                    }}</router-link>
                  </th>
                  <td>{{ $moneyFormat(item.cost) }}</td>
                  <td>{{ $moneyFormat(item.old_balance) }}</td>
                  <td>{{ $moneyFormat(item.new_balance) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <showFile />
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import showFile from '@/elements/show/showFile.vue'

export default {
  data() {
    return {
      path: '/partnersDeposits',
      item: {},
      printInvoice: false
    }
  },
  mounted() {
    this.getitem()
  },
  methods: {
    getitem() {
      axios.get(this.$linkGnirator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
      })
    },
    print(id) {
      this.$parent.printedSalesPayment = this.item
    }
  },
  components: {
    showFile
  }
}
</script>
