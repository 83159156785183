var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t('components.customersPurchasePayments.purchasePayments'))+" ")]),(_vm.$parent.item.stat == 1 && _vm.$checkRole('purchase_payments_add'))?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"title":"$t('components.customersPurchasePayments.AddABatch')","to":'/purchasePayments/create?customer_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.customersPurchasePayments.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$t('components.customersPurchasePayments.FindABatch'),"withoutCard":true,"emptyTableText":_vm.$parent.$t('components.customersPurchasePayments.thereAreNoPurchasePayments'),"filter":{ customer_id: _vm.$route.params.id },"emptyTableSubText":_vm.$parent.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
        {
          column: 'code',
          title: _vm.$t('components.customersPurchasePayments.Payment'),
          type: 'mainLink',
          sort: true
        },
        {
          column: 'client_type',
          title: _vm.$t('components.customersPurchasePayments.supplierName'),
          type: 'clintLink',
          sort: true
        },
        {
          column: 'date',
          title: _vm.$parent.$t('components.customersPurchasePayments.paymentDate'),
          type: 'text',
          sort: true
        },
        {
          column: 'bill_id',
          title: _vm.$parent.$t('components.customersPurchasePayments.bill'),
          type: 'linkInvoice',
          to: 'bill',
          sort: true,
          link: true
        },
        {
          column: 'description',
          title: _vm.$parent.$t('components.customersPurchasePayments.thatsAbout'),
          type: 'text',
          sort: true
        },
        {
          column: 'cost',
          title: _vm.$parent.$t('components.customersPurchasePayments.amount'),
          type: 'text',
          sort: true
        },
        {
          column: 'options',
          title: _vm.$parent.$t('components.customersPurchasePayments.settings'),
          type: 'options',
          options: [
            { name: 'show' },
            {
              name: 'edit',
              role: _vm.$checkRole('purchase_payments_edit')
            },
            { name: 'printPurchasePayment', role: true },
            { name: 'download', role: true },
            {
              name: 'delete',
              role: _vm.$checkRole('purchase_payments_delete')
            }
          ]
        }
      ],"deleteText":{
        attention: _vm.$parent.$t('allerts.Attention'),
        areYouReallySureToDeleteTheItem: _vm.$parent.$t('allerts.areYouReallySureToDeleteTheItem'),
        close: _vm.$parent.$t('allerts.close'),
        confirm: _vm.$parent.$t('allerts.confirm'),
        loading: _vm.$parent.$t('allerts.loading')
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }