<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('employeesWithdrawals.form.CreateAPull')"
          :description="$t('employeesWithdrawals.form.ThatIsWhereYouCanCreateANewPullout')"
          v-if="$route.name == 'employeesWithdrawalsCreate'"
        />
        <addHeader
          :title="$t('employeesWithdrawals.form.ModifyingTheWithdrawalProcess')"
          :description="$t('employeesWithdrawals.form.FromHereYouCanModifyWithdrawals')"
          v-if="$route.name == 'employeesWithdrawalsEdit'"
        />
        <div class="mb-4 row">
          <connectionInput
            :title="$t('employeesWithdrawals.form.ProcessNumbering')"
            :group="$t('employeesWithdrawals.form.Groups')"
            v-model="item.invoice_group"
            :disabled="$checkRole('employees_withdrawals_edit_item') ? false : true"
            :hasErorr="errors.invoice_group"
            :error="$t('employeesWithdrawals.form.ThisFieldIsRequired')"
            :values="$database.invoiceGroups"
            v-if="$route.name == 'employeesWithdrawalsCreate'"
          />
          <formInput
            :title="$t('employeesWithdrawals.form.OperationCode')"
            v-model="item.code"
            :disabled="true"
            v-if="$route.name == 'employeesWithdrawalsEdit'"
            maxlength="255"
          />
          <subFileInput
            :title="$t('invoices.form.invoiceFile')"
            v-model="item.file"
            :file="item.file"
            :path="'invoices'"
            :hasErorr="errors.file"
            :error="$t('allerts.ChooseASuitableFile')"
          />
          <formInput
            :title="$t('salesPayments.form.reference')"
            v-model="item.refrance"
            maxlength="255"
          />

          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{ $t('employeesWithdrawals.form.date') }}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>
                <VueCtkDateTimePicker
                  :label="$t('employeesWithdrawals.form.ChooseTimeAndDate')"
                  :button-now-translation="$t('employeesWithdrawals.form.Now')"
                  v-model="item.date"
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>

          <employeeNameInput :disabled="$route.name == 'employeesWithdrawalsEdit' ? true : false" />

          <monyInput
            :title="$t('employeesWithdrawals.form.balance')"
            v-model="item.employee.balance"
            disabled
          />

          <selectInput
            :title="$t('invoices.form.Situation')"
            v-model="item.status"
            :values="[
              { name: $t('invoices.draft'), value: 0 },
              { name: $t('invoices.approved'), value: 1 }
            ]"
          />

          <dvider
            :title="$t('employeesWithdrawals.form.accountsInformation')"
            :description="$t('employeesWithdrawals.form.NotesOnAccountInformation')"
          />

          <monyInput :title="$t('employeesWithdrawals.form.Amount')" v-model="item.cost" />

          <searchGroupInput :type="'safe'" :values="$database.safes" :inputs="[{ show: 'name' }]" />
          <dvider
            :title="$t('employeesWithdrawals.form.DragNotes')"
            :description="$t('employeesWithdrawals.form.WithdrawalNotes')"
          />
          <formTextarea
            :title="$t('employeesWithdrawals.form.thatAbout')"
            v-model="item.description"
          />
          <formTextarea :title="$t('employeesWithdrawals.form.Note')" v-model="item.notes" />
          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'employeesWithdrawalsCreate' && !$parent.stopEdit"
          >
            {{ $t('employeesWithdrawals.form.AddAnOperation') }}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'employeesWithdrawalsEdit' && !$parent.stopEdit"
          >
            {{ $t('employeesWithdrawals.form.ProcessModification') }}
          </a>
          <loading v-if="$parent.stopEdit" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import formInput from '@/elements/add/form/formInput.vue'
import formTextarea from '@/elements/add/form/formTextarea.vue'
import connectionInput from '@/elements/add/form/connectionInput.vue'
import employeeNameInput from '@/elements/add/form/search/employeeNameInput.vue'
import monyInput from '@/elements/add/form/monyInput.vue'
import selectInput from '@/elements/add/form/selectInput.vue'
import dvider from '@/elements/add/dvider.vue'
import addHeader from '@/elements/add/header.vue'
import loading from '@/elements/add/loading.vue'
import searchGroupInput from '@/elements/add/form/searchGroupInput.vue'
import subFileInput from '@/elements/add/form/subFileInput.vue'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

export default {
  data() {
    return {
      path: '/employeesWithdrawals',
      alertText: this.$t('allerts.pleaseMakeSureOfTheInput'),
      item: {
        invoice_group: this.$option.employees_withdrawals_invoice_group_id,
        safe_id: 0,
        date: '',
        employee_id: 0,
        cost: '',
        employee: {
          name: '',
          balance: 0
        },
        safe: {},
        status: 1
      },
      model: false,
      errors: {
        cost: false,
        safe_id: false,
        invoice_group: false
      }
    }
  },
  mounted() {
    this.$updateDatabase(['invoiceGroups', 'safes', 'paymentMethods'])
    if (this.$database.safes.length == 1) {
      this.item.safe_id = this.$database.safes[0].id
    }
    if (this.$route.name == 'employeesWithdrawalsEdit') {
      this.$parent.stopEdit = true
      this.getItem()
    } else {
      var params = this.$getParams()
      if (params.employee_id) {
        var employee = this.$database.employees.find((el) => el.id == params.employee_id)
        this.item.employee_id = params.employee_id
        this.item.employee = employee
      }
    }

    if (this.$route.name == 'employeesWithdrawalsCreate') {
      this.item.date = this.$nowDate()
    }

    var elm = this
    window.addEventListener('keydown', function (e) {
      if (e.key == 'F1' || e.key == 'Meta') {
        e.preventDefault()
        elm.saveItem()
      }
    })
  },
  methods: {
    getItem() {
      axios.get(this.$linkGnirator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
        this.$parent.stopEdit = false
        this.item.date = this.$dateFormat(this.item.date, true)
      })
    },
    saveItem() {
      var error = 0
      if (typeof this.item.cost === 'undefined' || this.item.cost == '' || this.item.cost < 1) {
        error = 1
        this.errors.cost = true
      }
      if (
        typeof this.item.safe_id === 'undefined' ||
        this.item.safe_id == '' ||
        this.item.safe_id == 0
      ) {
        error = 1
        this.errors.safe_id = true
      }
      if (
        (typeof this.item.invoice_group === 'undefined' ||
          this.item.invoice_group == 0 ||
          this.item.invoice_group == '' ||
          this.item.invoice_group == null) &&
        this.$route.name == 'employeesWithdrawalsCreate'
      ) {
        error = 1
        this.errors.invoice_group = true
      }

      if (this.item.cost > this.item.safe.balance) {
        error = 1
        this.alertText = 'رصيد الخزينة لا يسمح'
      }
      if (error == 0) {
        this.item.date = this.$fixDate(this.item.date)
        if (this.$route.name == 'employeesWithdrawalsCreate') {
          this.$parent.aletText = this.$t('allerts.expenseSuccessfullyAdde')
          this.$parent.alertType = 'success'
          this.$addToLocalDatabase(this.item, this.path.substring(1))

          // update empolyee balance
          var employee = this.$database.employees.find((el) => el.id == this.item.employee_id)
          if (employee) {
            employee.balance = parseFloat(employee.balance) - parseFloat(this.item.cost)
          }
          // update empolyee balance
          var safe = this.$database.safes.find((el) => el.id == this.item.safe_id)
          if (safe) {
            safe.balance = parseFloat(safe.balance) - parseFloat(this.item.cost)
          }

          localStorage.database = JSON.stringify(this.$database)
          this.$router.go(-1)
          this.$parent.stopEdit = false
        } else if (this.$route.name == 'employeesWithdrawalsEdit') {
          this.item._method = 'PUT'
          this.$parent.stopEdit = true
          axios
            .post(this.$linkGnirator(this.path + '/' + this.item.id), this.item)
            .then((response) => {
              this.$parent.aletText = this.$t('allerts.expenseHasBeenModifiedSuccessfully')
              this.$parent.alertType = 'success'
              this.$router.go(-1)
              this.$parent.stopEdit = false
              return response
            })
        }
      } else {
        this.$parent.aletText = this.alertText
        this.$parent.alertType = 'danger'
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    }
  },

  components: {
    formInput,
    dvider,
    addHeader,
    monyInput,
    selectInput,
    formTextarea,
    connectionInput,
    searchGroupInput,
    loading,
    employeeNameInput,
    VueCtkDateTimePicker,
    subFileInput
  }
}
</script>
