import salesChequesIndex from '@/pages/cheques/salesCheques/index.vue'
import salesChequesForm from '@/pages/cheques/salesCheques/form.vue'
import salesChequesShow from '@/pages/cheques/salesCheques/show.vue'

import purchaseChequesIndex from '@/pages/cheques/purchaseCheques/index.vue'
import purchaseChequesForm from '@/pages/cheques/purchaseCheques/form.vue'
import purchaseChequesShow from '@/pages/cheques/purchaseCheques/show.vue'

const routes = [
  {
    path: 'salesCheques',
    name: 'salesCheques',
    component: salesChequesIndex
  },
  {
    path: 'salesCheques/create',
    name: 'salesChequesCreate',
    component: salesChequesForm
  },
  {
    path: 'salesCheques/:id/edit',
    name: 'salesChequesEdit',
    component: salesChequesForm
  },
  {
    path: 'salesCheques/:id',
    name: 'saleschequesShow',
    component: salesChequesShow
  },

  //purchaseCheques
  {
    path: 'purchaseCheques',
    name: 'purchaseCheques',
    component: purchaseChequesIndex
  },
  {
    path: 'purchaseCheques/create',
    name: 'purchaseChequesCreate',
    component: purchaseChequesForm
  },
  {
    path: 'purchaseCheques/:id/edit',
    name: 'purchaseChequesEdit',
    component: purchaseChequesForm
  },
  {
    path: 'purchaseCheques/:id',
    name: 'purchaseChequesShow',
    component: purchaseChequesShow
  }
]

export default routes
