var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('capitals.safes'),"description":_vm.$t('capitals.safesDescription')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('capitals.SearchForOrigin'),"emptyTableText":_vm.$t('capitals.thereAreNosafes'),"buttonRole":_vm.$checkRole('capitals_add'),"localData":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
          {
            column: 'name',
            title: _vm.$t('capitals.customerName'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'cost',
            title: 'سعر الشراء',
            type: 'text',
            sort: true,
            icon: 'money-bill-wave'
          },
          {
            column: 'date',
            title: _vm.$t('capitals.PurchaseDate'),
            type: 'text',
            sort: true,
            icon: 'calendar-alt'
          },
          {
            column: 'total',
            title: 'القيمة الحالية',
            type: 'text',
            sort: true,
            icon: 'money-bill-wave'
          },
          {
            column: 'stat',
            title: _vm.$t('capitals.active'),
            type: 'stat',
            sort: true,
            hideMobile: true
          },
          {
            column: 'options',
            title: _vm.$t('capitals.actions'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('capitals_edit')
              },
              {
                name: 'delete',
                role: _vm.$checkRole('capitals_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }