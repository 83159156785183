var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t('components.customersSalesReturns.invoices'))+" ")]),(_vm.$parent.item.stat == 1 && _vm.$checkRole('invoices_add'))?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/saleReturns/create?customer_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.customersSalesReturns.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$parent.$t('components.customersSalesReturns.searchForAnInvoice'),"withoutCard":true,"emptyTableText":_vm.$parent.$t('components.customersSalesReturns.thereAreNoBills'),"filter":{ customer_id: _vm.$route.params.id },"emptyTableSubText":_vm.$parent.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"statusSearch":true,"statusValues":[
        {
          title: _vm.$parent.$t('components.customersSalesReturns.situation'),
          value: ''
        },
        {
          title: _vm.$parent.$t('components.customersSalesReturns.all'),
          value: ''
        },
        {
          title: _vm.$parent.$t('components.customersSalesReturns.draft'),
          value: 0
        },
        {
          title: _vm.$parent.$t('components.customersSalesReturns.sent'),
          value: 1
        },
        {
          title: _vm.$parent.$t('components.customersSalesReturns.seen'),
          value: 2
        },
        {
          title: _vm.$parent.$t('components.customersSalesReturns.driven'),
          value: 3
        },
        {
          title: _vm.$parent.$t('components.customersSalesReturns.canceled'),
          value: 4
        }
      ],"cloumns":[
        {
          column: 'code',
          title: _vm.$parent.$t('components.customersSalesReturns.invoice'),
          type: 'mainLink',
          sort: true
        },
        {
          column: 'date',
          title: _vm.$parent.$t('components.customersSalesReturns.theDateOfTheInvoice'),
          type: 'text',
          sort: true
        },
        {
          column: 'customer_id',
          title: _vm.$parent.$t('components.customersSalesReturns.customerName'),
          type: 'link',
          to: 'customer',
          sort: true,
          link: true
        },
        {
          column: 'total',
          title: _vm.$parent.$t('components.customersSalesReturns.cost'),
          type: 'text',
          sort: true
        },
        {
          column: 'rest',
          title: _vm.$parent.$t('components.customersSalesReturns.balance'),
          type: 'text',
          sort: true
        },
        {
          column: 'status',
          title: _vm.$parent.$t('components.customersSalesReturns.situation'),
          type: 'status',
          sort: true,
          values: [
            {
              title: _vm.$parent.$t('components.customersSalesReturns.draft'),
              value: 0,
              color: 'dark'
            },
            {
              title: _vm.$parent.$t('components.customersSalesReturns.sent'),
              value: 1,
              color: 'primary'
            },
            {
              title: _vm.$parent.$t('components.customersSalesReturns.seen'),
              value: 2,
              color: 'primary'
            },
            {
              title: _vm.$parent.$t('components.customersSalesReturns.driven'),
              value: 3,
              color: 'success'
            },
            {
              title: _vm.$parent.$t('components.customersSalesReturns.canceled'),
              value: 4,
              color: 'danger'
            }
          ]
        },
        {
          column: 'options',
          title: _vm.$parent.$t('components.customersSalesReturns.settings'),
          type: 'options',
          options: [
            { name: 'show' },
            {
              name: 'edit',
              role: _vm.$checkRole('sale_returns_edit')
            },
            { name: 'outLink', role: true },
            { name: 'printSaleReturn', role: true },
            { name: 'download', role: true },
            {
              name: 'delete',
              role: _vm.$checkRole('sale_returns_delete')
            }
          ]
        }
      ],"deleteText":{
        attention: _vm.$parent.$t('allerts.Attention'),
        areYouReallySureToDeleteTheItem: _vm.$parent.$t('allerts.areYouReallySureToDeleteTheItem'),
        close: _vm.$parent.$t('allerts.close'),
        confirm: _vm.$parent.$t('allerts.confirm'),
        loading: _vm.$parent.$t('allerts.loading')
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }