var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('storesWithdrawals.storesWithdrawalsOperations'),"description":_vm.$t('storesWithdrawals.fromHereYouCanControlstoresWithdrawalsOperations')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('storesWithdrawals.searchForAnstoresWithdrawalsOperations'),"emptyTableText":_vm.$t('storesWithdrawals.thereAreNostoresWithdrawalsOperations'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$checkRole('stores_withdrawals_add'),"statusSearch":true,"statusValues":[
          { title: _vm.$t('orders.situation'), value: '' },
          { title: _vm.$t('orders.all'), value: '' },
          { title: _vm.$t('invoices.draft'), value: 0 },
          { title: _vm.$t('invoices.approved'), value: 1 }
        ],"cloumns":[
          {
            column: 'code',
            title: _vm.$t('storesWithdrawals.operation'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'store_id',
            title: _vm.$t('storesWithdrawals.storeName'),
            type: 'link',
            to: 'store',
            sort: true,
            link: true
          },
          {
            column: 'date',
            title: _vm.$t('storesWithdrawals.theDateOfTheOperation'),
            type: 'text',
            sort: true
          },
          {
            column: 'description',
            title: _vm.$t('storesWithdrawals.description'),
            type: 'text',
            sort: true
          },
          {
            column: 'user_name',
            title: 'اسم المسئول',
            type: 'text',
            sort: true
          },
          {
            column: 'status',
            title: _vm.$t('bills.situation'),
            type: 'status',
            sort: true,
            values: [
              { title: _vm.$t('invoices.draft'), value: 0, color: 'dark' },
              { title: _vm.$t('invoices.approved'), value: 1, color: 'success' }
            ]
          },
          {
            column: 'options',
            title: _vm.$t('storesWithdrawals.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('stores_withdrawals_edit')
              },
              { name: 'file' },
              { name: 'printStoresWithdrawal', role: true },
              {
                name: 'delete',
                role: _vm.$checkRole('stores_withdrawals_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }