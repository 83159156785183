var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('bills.bills'),"description":_vm.$t('bills.fromHereYouCanControlPurchaseInvoices')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('bills.searchForABill'),"emptyTableText":_vm.$t('bills.thereAreNoBills'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$checkRole('bills_add'),"statusSearch":true,"statusValues":[
          { title: _vm.$t('bills.situation'), value: '' },
          { title: _vm.$t('bills.all'), value: '' },
          { title: _vm.$t('bills.open'), value: 0 },
          { title: _vm.$t('bills.driven'), value: 1 },
          { title: _vm.$t('bills.canceled'), value: 2 }
        ],"cloumns":[
          {
            column: 'code',
            title: _vm.$t('bills.bill'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'date',
            title: _vm.$t('bills.theDateOfTheInvoice'),
            type: 'text',
            sort: true
          },
          {
            column: 'client_type',
            title: _vm.$t('bills.supplierName'),
            type: 'clintLink',
            sort: true
          },
          {
            column: 'total',
            title: _vm.$t('bills.cost'),
            type: 'text',
            sort: true
          },
          {
            column: 'rest',
            title: _vm.$t('bills.balance'),
            type: 'text',
            sort: true
          },
          {
            column: 'user_name',
            title: 'اسم المسئول',
            type: 'text',
            sort: true
          },
          {
            column: 'status',
            title: _vm.$t('bills.situation'),
            type: 'status',
            sort: true,
            values: [
              { title: _vm.$t('bills.open'), value: 0, color: 'dark' },
              { title: _vm.$t('bills.driven'), value: 1, color: 'success' },
              { title: _vm.$t('bills.canceled'), value: 2, color: 'danger' }
            ]
          },
          {
            column: 'options',
            title: _vm.$t('bills.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              { name: 'edit', role: _vm.$checkRole('bills_edit') },
              { name: 'file' },
              {
                name: 'invoiceLink',
                role: _vm.$checkRole('purchase_returns_add'),
                title: _vm.$t('bills.ReturnBuying'),
                icon: 'exchange',
                link: '/purchaseReturns/create?bill_id='
              },
              {
                name: 'invoiceLink',
                role: true,
                title: _vm.$t('bills.BarcodePrint'),
                icon: 'barcode-alt',
                link: '/productsBarcode/bill/'
              },
              { name: 'outLink', role: true },
              { name: 'printBill', role: true },
              { name: 'download', role: true },
              {
                name: 'delete',
                role: _vm.$checkRole('bills_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }