var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('addHeader',{attrs:{"title":_vm.$t('mainReport.Reports'),"description":_vm.$t('mainReport.TheReportingPageGivesYouAGlimpseOfWhatIsGoingOnInYourBusiness')}}),_c('div',{staticClass:"row"},[_c('reportLink',{staticClass:"col-md-4 col-lg-2",attrs:{"to":'/overview',"icon":'tachometer-alt-fastest',"title":'لمحة عامة',"text":'من هنا يمكنك التحكم بتقارير لمحة عامة الخاصة بك'}}),(
            (_vm.$site.customers_allow || _vm.$site.orders_allow || _vm.$site.invoices_allow) &&
            (_vm.$user.role.available_reports.find(
              (el) =>
                el.id == 1 ||
                el.id == 2 ||
                el.id == 3 ||
                el.id == 4 ||
                el.id == 13 ||
                el.id == 14 ||
                el.id == 21 ||
                el.id == 22 ||
                el.id == 17 ||
                el.id == 18
            ) ||
              _vm.$user.admin)
          )?_c('reportLink',{staticClass:"col-md-4 col-lg-2",attrs:{"to":'/sales',"icon":'chart-line',"title":_vm.$t('mainReport.sales'),"text":_vm.$t('mainReport.Fromhereyoucancontrolyoursalesreports')}}):_vm._e(),(
            (_vm.$site.suppliers_allow || _vm.$site.orders_allow || _vm.$site.bills_allow) &&
            (_vm.$user.role.available_reports.find(
              (el) =>
                el.id == 5 ||
                el.id == 6 ||
                el.id == 7 ||
                el.id == 8 ||
                el.id == 15 ||
                el.id == 16 ||
                el.id == 23 ||
                el.id == 24 ||
                el.id == 19 ||
                el.id == 20
            ) ||
              _vm.$user.admin)
          )?_c('reportLink',{staticClass:"col-md-4 col-lg-2",attrs:{"to":'/purchases',"icon":'shopping-cart',"title":_vm.$t('mainReport.Purchases'),"text":_vm.$t('mainReport.Fromhereyoucancontrolyourpurchasereports')}}):_vm._e(),(
            (_vm.$site.safes_allow ||
              _vm.$site.salesPayments_allow ||
              _vm.$site.purchasePayments_allow ||
              _vm.$site.expenses_allow ||
              _vm.$site.safesDeposits_allow ||
              _vm.$site.safesWithdrawals_allow ||
              _vm.$site.safesTransfers_allow) &&
            (_vm.$user.role.available_reports.find(
              (el) =>
                el.id == 9 ||
                el.id == 10 ||
                el.id == 25 ||
                el.id == 26 ||
                el.id == 31 ||
                el.id == 32 ||
                el.id == 13 ||
                el.id == 14
            ) ||
              _vm.$user.admin)
          )?_c('reportLink',{staticClass:"col-md-4 col-lg-2",attrs:{"to":'/lockers',"icon":'box-open',"title":_vm.$t('mainReport.lockers'),"text":_vm.$t('mainReport.Fromhereyoucancontrolyourlockersreports')}}):_vm._e(),(
            (_vm.$site.stores_allow ||
              _vm.$site.storesDeposits_allow ||
              _vm.$site.storesWithdrawals_allow ||
              _vm.$site.storesTransfers_allow) &&
            (_vm.$user.role.available_reports.find(
              (el) => el.id == 11 || el.id == 12 || el.id == 52 || el.id == 13 || el.id == 14
            ) ||
              _vm.$user.admin)
          )?_c('reportLink',{staticClass:"col-md-4 col-lg-2",attrs:{"to":'/stocks',"icon":'store-alt',"title":_vm.$t('mainReport.stores'),"text":_vm.$t('mainReport.Fromhereyoucancontrolyourstoresreports')}}):_vm._e(),(
            (_vm.$site.employees_allow ||
              _vm.$site.employeesSalaries_allow ||
              _vm.$site.employeesIncentives_allow ||
              _vm.$site.employeesDeductions_allow ||
              _vm.$site.employeesWithdrawals_allow) &&
            (_vm.$user.role.available_reports.find(
              (el) => el.id == 42 || el.id == 43 || el.id == 44 || el.id == 45
            ) ||
              _vm.$user.admin)
          )?_c('reportLink',{staticClass:"col-md-4 col-lg-2",attrs:{"to":'/employee',"icon":'users',"title":_vm.$t('mainReport.employees'),"text":_vm.$t('mainReport.Fromhereyoucancontrolyouremployeesreports')}}):_vm._e(),(
            (_vm.$site.accounts_allow ||
              _vm.$site.accountsDeposits_allow ||
              _vm.$site.accountsWithdrawals_allow) &&
            (_vm.$user.role.available_reports.find(
              (el) =>
                el.id == 34 ||
                el.id == 35 ||
                el.id == 36 ||
                el.id == 37 ||
                el.id == 31 ||
                el.id == 32
            ) ||
              _vm.$user.admin)
          )?_c('reportLink',{staticClass:"col-md-4 col-lg-2",attrs:{"to":'/accountOperation',"icon":'hand-holding-usd',"title":_vm.$t('mainReport.accountOperations'),"text":_vm.$t('mainReport.FromhereyoucancontrolyouraccountOperationsreports')}}):_vm._e(),(
            (_vm.$site.salesCheques_allow || _vm.$site.purchaseCheques_allow) &&
            (_vm.$user.role.available_reports.find((el) => el.id == 27 || el.id == 28) || _vm.$user.admin)
          )?_c('reportLink',{staticClass:"col-md-4 col-lg-2",attrs:{"to":'/checks',"icon":'money-check-alt',"title":_vm.$t('mainReport.Checks'),"text":_vm.$t('mainReport.FromhereyoucancontrolyourChecksreports')}}):_vm._e(),(
            (_vm.$site.salesDiscounts_allow || _vm.$site.purchaseDiscounts_allow) &&
            (_vm.$user.role.available_reports.find((el) => el.id == 29 || el.id == 30) || _vm.$user.admin)
          )?_c('reportLink',{staticClass:"col-md-4 col-lg-2",attrs:{"to":'/discount',"icon":'badge-percent',"title":_vm.$t('mainReport.Discounts'),"text":_vm.$t('mainReport.FromhereyoucancontrolyourDiscountsreports')}}):_vm._e(),(
            _vm.$site.pointOfSales_allow &&
            (_vm.$user.role.available_reports.find((el) => el.id == 0) || _vm.$user.admin)
          )?_c('reportLink',{staticClass:"col-md-4 col-lg-2",attrs:{"to":'/pointOfSale',"icon":'cash-register',"title":_vm.$t('mainReport.pointOfSale'),"text":_vm.$t('mainReport.FromhereyoucancontrolyourpointOfSalereports')}}):_vm._e(),(
            _vm.$site.cities_allow &&
            (_vm.$user.role.available_reports.find((el) => el.id == 13 || el.id == 14) || _vm.$user.admin)
          )?_c('reportLink',{staticClass:"col-md-4 col-lg-2",attrs:{"to":'/shipping',"icon":'shipping-fast',"title":_vm.$t('mainReport.shippingOperations'),"text":_vm.$t('mainReport.FromhereyoucancontrolyourshippingOperationsreports')}}):_vm._e(),(
            (_vm.$site.partners_allow ||
              _vm.$site.partnersDeposits_allow ||
              _vm.$site.partnersWithdrawals_allow ||
              _vm.$site.partnersSettlements_allow ||
              _vm.$site.partnersProfits_allow) &&
            (_vm.$user.role.available_reports.find(
              (el) =>
                el.id == 34 ||
                el.id == 35 ||
                el.id == 36 ||
                el.id == 37 ||
                el.id == 31 ||
                el.id == 32
            ) ||
              _vm.$user.admin)
          )?_c('reportLink',{staticClass:"col-md-4 col-lg-2",attrs:{"to":'/partner',"icon":'handshake',"title":_vm.$t('mainReport.partners'),"text":_vm.$t('mainReport.Fromhereyoucancontrolyourpartnersreports')}}):_vm._e()],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }