<template>
  <div class="row container-fluid">
    <div class="col-12">
      <indexHeader
        :title="$t('mainReport.Checks')"
        :description="$t('mainReport.FromhereyoucancontrolyourChecksreports')"
      />
    </div>
    <!-- تقارير الشيكات -->
    <reportsCard
      class="col-md-6"
      :cardTitle="$t('mainReport.Checks')"
      :icon="'fa-money-check-alt'"
      v-if="
        ($user.role.available_reports.find((el) => el.id == 27 || el.id == 28) || $user.admin) &&
        ($site.salesCheques_allow || $site.purchaseCheques_allow) &&
        ($user.admin || $user.role.sales_cheques_show || $user.role.purchase_cheques_show)
      "
      :reports="[
        {
          name: $t('mainReport.RegisterSalesCheques'),
          link: './chequesHistory',
          role: $user.role.available_reports.find((el) => el.id == 27) || $user.admin
        },
        {
          name: $t('mainReport.CheckInRecord'),
          link: '/purchaseChequesHistory',
          role: $user.role.available_reports.find((el) => el.id == 28) || $user.admin
        }
      ]"
    />
  </div>
</template>
<script>
import reportsCard from '@/elements/reports/reportsCard.vue'
import indexHeader from '@/elements/index/indexHeader.vue'
export default {
  components: {
    reportsCard,
    indexHeader
  }
}
</script>
