import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ar from '@/locales/ar.json'
import en from '@/locales/en.json'

const defaultImpl = VueI18n.prototype.getChoiceIndex

VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
  // this === VueI18n instance, so the locale property also exists here

  if (choice === 0) {
    return 0
  }

  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1

  if (!teen && endsWithOne) {
    return 1
  }

  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }

  return choicesLength < 4 ? 2 : 3
}

Vue.use(VueI18n)

const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }
  },
  ar: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }
  }
}

const numberFormats = {
  en: {
    currency: {
      style: 'currency',
      currency: 'USD'
    }
  },
  ar: {
    currency: {
      style: 'currency',
      currency: 'RUB'
    }
  }
}

export const i18n = new VueI18n({
  locale: 'ar',
  fallbackLocale: 'ar',
  messages: { ar, en },
  dateTimeFormats,
  numberFormats
})
