<template>
  <div class="container-fluid" v-if="!loading">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col ml-n2">
                <h4 class="mb-1">
                  <!-- <a>{{$t('bills.form.invoice')}} <strong># {{item.code}}</strong></a> -->
                </h4>
              </div>
              <div class="col-auto">
                <router-link
                  :to="path + '/' + item.id + '/edit'"
                  class="btn btn-sm btn-primary"
                  :title="$t('bills.show.modulation')"
                >
                  <i class="fas fa-edit"></i>
                </router-link>
                <a
                  :href="site + '/bills/' + item.password + '/customer/show'"
                  class="btn btn-sm btn-primary"
                  target="_blank"
                  :title="$t('bills.show.ExternalLink')"
                >
                  <i class="fas fa-link"></i>
                </a>
                <a
                  @click="print()"
                  class="btn btn-sm btn-primary"
                  :title="$t('bills.show.Printing')"
                >
                  <i class="fas fa-print"></i>
                </a>
                <a
                  :href="$linkGnirator('/bills/' + item.id + '/pdf')"
                  download
                  class="btn btn-sm btn-primary"
                  :title="$t('bills.show.DownloadPDF')"
                >
                  <i class="fas fa-download"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <div class="card card-body p-5 invoice-show">
          <div class="row">
            <div class="col">
              <div class="badge badge-dark" v-if="item.status == 0">
                {{ $t('bills.open') }}
              </div>
              <div class="badge badge-success" v-if="item.status == 1">
                {{ item.rest == 0 ? $t('bills.driven') : $t('bills.partiallyDriven') }}
              </div>
              <div class="badge badge-danger" v-if="item.status == 2">
                {{ $t('bills.canceled') }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <BillsComponent :item="item" :loading="loading" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">{{ $t('bills.bill') }} : #{{ item.code }}</h4>
          </div>
          <div class="card-body">
            <ul class="list-unstyled">
              <li>
                <strong>{{ $t('invoices.show.createdIn') }}</strong> :
                {{ item.created }}
              </li>
              <li>
                <strong>{{ $t('invoices.show.LastUpdate') }}</strong> :
                {{ item.updated }}
              </li>
              <li v-if="item.client_type == 0 && item.customer">
                <strong>{{ $t('invoices.show.Client') }}</strong> :
                <router-link :to="'/customers/' + item.customer.id">
                  {{ item.customer.name }}</router-link
                >
              </li>
              <li v-if="item.client_type == 1 && item.supplier">
                <strong>{{ $t('invoices.show.Client') }}</strong> :
                <router-link :to="'/suppliers/' + item.supplier.id">
                  {{ item.supplier.name }}</router-link
                >
              </li>
              <li>
                <strong>{{ $t('bills.show.Administrator') }}</strong> :
                {{ item.user.name }}
              </li>
              <li>
                <strong>{{ $t('bills.show.Store') }}</strong> :
                <router-link :to="'/stores/' + item.store.id" v-if="item.store">
                  {{ item.store.name }}</router-link
                >
                {{ item.store ? '' : '--' }}
              </li>
            </ul>
          </div>
        </div>
        <div class="card" v-if="storesHistories.length != 0">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="far fa-store-alt"></i>
              {{ $t('bills.show.WarehouseOperations') }}
            </h4>
          </div>
          <table class="table table-sm main-show">
            <thead>
              <tr>
                <th scope="col">{{ $t('bills.show.Lots') }}</th>
                <th scope="col">{{ $t('bills.show.theProduct') }}</th>
                <th scope="col">{{ $t('bills.show.TheStore') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="storeHistory in storesHistories" :key="storeHistory.id">
                <td>
                  {{ storeHistory.type == 0 ? '+' : '-' }}
                  {{ storeHistory.quantity }}
                </td>
                <td>
                  <router-link :to="'/products/' + storeHistory.product.id" class="btn btn-light">
                    {{ storeHistory.product.name }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    v-if="storeHistory.store"
                    :to="'/stores/' + storeHistory.store.id"
                    class="btn btn-light"
                  >
                    {{ storeHistory.store.name }}
                  </router-link>
                  {{ storeHistory.store ? '' : '--' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <showFile />
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import showFile from '@/elements/show/showFile.vue'
import BillsComponent from '@/print/components/bills.vue'

export default {
  data() {
    return {
      path: '/bills',
      logo: localStorage.getItem('logo'),
      item: {},
      purchasePayments: {},
      storesHistories: {},
      purchasePaymentForm: false,
      addId: this.$route.params.id,
      amount: 0,
      type: 'show',
      loading: true,
      itemsCount: 0,

      printInvoice: false,
      site: localStorage.getItem('site')
    }
  },
  mounted() {
    this.getitem()
  },
  methods: {
    getitem() {
      axios.get(this.$linkGnirator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
        this.purchasePayments = response.data.purchase_payments
        this.storesHistories = this.item.stores_histories
        this.amount = this.item.rest
        this.item.quotationItems.forEach((item) => {
          this.itemsCount += item.quantity - 0
        })
        this.loading = false
      })
    },
    print() {
      this.$parent.printedBill = this.item
    }
  },
  components: {
    showFile,
    BillsComponent
  }
}
</script>
