var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('purchaseReturns.bills'),"description":_vm.$t('purchaseReturns.fromHereYouCanControlPurchaseInvoices')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('purchaseReturns.searchForABill'),"emptyTableText":_vm.$t('purchaseReturns.thereAreNoBills'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$checkRole('purchase_returns_add'),"statusSearch":true,"statusValues":[
          { title: _vm.$t('quotations.situation'), value: '' },
          { title: _vm.$t('quotations.all'), value: '' },
          { title: _vm.$t('purchaseReturns.open'), value: 0 },
          { title: _vm.$t('purchaseReturns.driven'), value: 1 },
          { title: _vm.$t('purchaseReturns.canceled'), value: 2 }
        ],"cloumns":[
          {
            column: 'code',
            title: _vm.$t('purchaseReturns.bill'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'date',
            title: _vm.$t('purchaseReturns.theDateOfTheInvoice'),
            type: 'text',
            sort: true
          },
          {
            column: 'client_type',
            title: _vm.$t('bills.supplierName'),
            type: 'clintLink',
            sort: true
          },
          {
            column: 'total',
            title: _vm.$t('purchaseReturns.cost'),
            type: 'text',
            sort: true
          },
          {
            column: 'rest',
            title: _vm.$t('purchaseReturns.balance'),
            type: 'text',
            sort: true
          },
          {
            column: 'user_name',
            title: 'اسم المسئول',
            type: 'text',
            sort: true
          },
          {
            column: 'status',
            title: _vm.$t('purchaseReturns.situation'),
            type: 'status',
            sort: true,
            values: [
              { title: _vm.$t('purchaseReturns.open'), value: 0, color: 'dark' },
              {
                title: _vm.$t('purchaseReturns.driven'),
                value: 1,
                color: 'success'
              },
              {
                title: _vm.$t('purchaseReturns.canceled'),
                value: 2,
                color: 'danger'
              }
            ]
          },
          {
            column: 'options',
            title: _vm.$t('purchaseReturns.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('purchase_returns_edit')
              },
              { name: 'file' },
              { name: 'outLink', role: true },
              { name: 'printPurchaseReturn', role: true },
              { name: 'download', role: true },
              {
                name: 'delete',
                role: _vm.$checkRole('purchase_returns_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }