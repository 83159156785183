var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"icon":'store-alt',"title":_vm.item.name,"subData":[
        { title: _vm.$t('stores.form.theState'), value: _vm.item.country },
        { title: _vm.$t('stores.form.city'), value: _vm.item.city }
      ],"buttons":[
        {
          title: _vm.$t('stores.show.modulation'),
          icon: 'edit',
          link: _vm.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$checkRole('stores_edit')
        },
        {
          title: _vm.$t('stores.show.InventoryOfTheStore'),
          icon: 'file-chart-pie',
          link: '/warehouseInventory?store_id=' + _vm.$route.params.id,
          role: _vm.$checkRole('reports_show')
        }
      ]}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('stores.show.StoreAddress'),"cloumns":{
        firstCloum: [
          {
            icon: 'map-marker-alt',
            title: _vm.$t('stores.show.Address'),
            value: _vm.item.address1
          },
          {
            icon: 'location-arrow',
            title: _vm.$t('stores.show.TheSide'),
            value: _vm.item.entity
          },
          {
            icon: 'map-marked',
            title: _vm.$t('stores.show.ZipCode'),
            value: _vm.item.zip
          }
        ],
        scondCloum: [
          {
            icon: 'map-marker-alt',
            title: _vm.$t('stores.show.TheSequel'),
            value: _vm.item.address2
          },
          { icon: 'city', title: _vm.$t('stores.show.City'), value: _vm.item.city },
          {
            icon: 'globe-americas',
            title: _vm.$t('stores.show.State'),
            value: _vm.item.country
          }
        ]
      }}}),(_vm.$checkRole('invoices_show'))?_c('invoices',{staticClass:"col-12"}):_vm._e(),(_vm.$checkRole('bills_show'))?_c('bills',{staticClass:"col-12"}):_vm._e(),(_vm.$checkRole('sale_returns_show'))?_c('salesReturns',{staticClass:"col-12"}):_vm._e(),(_vm.$checkRole('purchase_returns_show'))?_c('purchaseReturns',{staticClass:"col-12"}):_vm._e(),(_vm.$checkRole('stores_deposits_show'))?_c('storesDeposits',{staticClass:"col-12"}):_vm._e(),(_vm.$checkRole('stores_withdrawals_show'))?_c('storesWithdrawals',{staticClass:"col-12"}):_vm._e(),(_vm.$checkRole('stores_transfers_show'))?_c('fromStoresTransfers',{staticClass:"col-12"}):_vm._e(),(_vm.$checkRole('stores_transfers_show'))?_c('toStoresTransfers',{staticClass:"col-12"}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }