<template>
  <div class="container-fluid">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col ml-n2">
                <h4 class="mb-1">
                  <a
                    >{{ $t('partnersSettlements.show.projectsSettlement') }}
                    <strong> {{ item.code }}</strong></a
                  >
                </h4>
              </div>
              <div class="col-auto">
                <router-link
                  :to="path + '/' + item.id + '/edit'"
                  class="btn btn-sm btn-primary"
                  :title="$t('partnersSettlements.show.edit')"
                  v-if="$checkRole('partners_settlements_edit')"
                >
                  <i class="fas fa-edit"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-credit-card-front"></i>
              {{ $t('partnersSettlements.show.PaymentDetails') }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <table class="table table-sm table-nowrap customed-table-small">
                  <tbody>
                    <tr>
                      <th scope="row">{{ $t('partnersSettlements.form.PaymentNumber') }}</th>
                      <td>{{ item.code }}</td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('partnersSettlements.form.PaymentDate') }}</th>
                      <td>{{ $dateFormat(item.date) }}</td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('partnersSettlements.show.Administrator') }}</th>
                      <td>{{ item.user ? item.user.name : '--' }}</td>
                    </tr>
                    <tr>
                      <th scope="row">{{ $t('partnersSettlements.show.Project') }}</th>
                      <td>
                        <router-link :to="'/projects/' + item.project.id" v-if="item.project">{{
                          item.project.name
                        }}</router-link>
                        {{ item.project ? '' : '--' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-3">
                <div class="price-show">
                  <div class="price-content">
                    <p>{{ $t('partnersSettlements.show.SettlementAmount') }}</p>
                    <h1>{{ $moneyFormat(item.total) }}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              <i class="fal fa-user-alt"></i>
              {{ $t('partnersSettlements.show.PayFromThe') }}
            </h4>
          </div>
          <div class="">
            <table class="table table-sm table-nowrap card-table table-hover">
              <thead>
                <tr>
                  <th scope="col">{{ $t('partnersSettlements.show.Partner') }}</th>
                  <th scope="col">{{ $t('partnersSettlements.show.cost') }}</th>
                  <th scope="col">{{ $t('partnersSettlements.show.Ratio') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(sItem, index) in item.quotationItems" :key="index">
                  <td>{{ sItem.partner_name }}</td>
                  <td>{{ $moneyFormat(sItem.cost) }}</td>
                  <td>{{ ((sItem.cost / item.total) * 100).toFixed(2) }} %</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <showFile />
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import showFile from '@/elements/show/showFile.vue'

export default {
  data() {
    return {
      path: '/partnersSettlements',
      item: {}
    }
  },
  mounted() {
    this.getitem()
  },
  methods: {
    getitem() {
      axios.get(this.$linkGnirator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
      })
    }
  },
  components: {
    showFile
  }
}
</script>
