var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('manufacturingWithdrawals.ManufacturingWithdrawals'),"description":_vm.$t('manufacturingWithdrawals.FromHereYouCanControlManufacturingWithdrawals')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('manufacturingWithdrawals.SearchForAnOperation'),"emptyTableText":_vm.$t('manufacturingWithdrawals.ThereAreNoWithdrawalsWithManufacturingProcesses'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":false,"statusSearch":true,"statusValues":[
          { title: _vm.$t('orders.situation'), value: '' },
          { title: _vm.$t('orders.all'), value: '' },
          { title: _vm.$t('invoices.draft'), value: 0 },
          { title: _vm.$t('invoices.approved'), value: 1 }
        ],"cloumns":[
          {
            column: 'code',
            title: _vm.$t('manufacturingWithdrawals.operation'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'manufacturing_operation_id',
            title: _vm.$t('manufacturingWithdrawals.TheNameOfTheManufacturingProcess'),
            type: 'linkInvoice',
            to: 'manufacturingOperation',
            sort: true,
            link: true
          },
          {
            column: 'store_id',
            title: _vm.$t('manufacturingWithdrawals.storeName'),
            type: 'link',
            to: 'store',
            sort: true,
            link: true
          },
          {
            column: 'date',
            title: _vm.$t('manufacturingWithdrawals.theDateOfTheOperation'),
            type: 'text',
            sort: true
          },
          {
            column: 'price',
            title: _vm.$t('manufacturingWithdrawals.price'),
            type: 'text',
            sort: true
          },
          {
            column: 'description',
            title: _vm.$t('manufacturingWithdrawals.thatAbout'),
            type: 'text',
            sort: true
          },
          {
            column: 'status',
            title: _vm.$t('bills.situation'),
            type: 'status',
            sort: true,
            values: [
              { title: _vm.$t('invoices.draft'), value: 0, color: 'dark' },
              { title: _vm.$t('invoices.approved'), value: 1, color: 'success' }
            ]
          },
          {
            column: 'options',
            title: _vm.$t('manufacturingWithdrawals.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('manufacturing_operations_edit')
              },
              { name: 'file' },
              { name: 'printprojectsWithdrawal', role: true },
              {
                name: 'delete',
                role: _vm.$checkRole('manufacturing_operations_edit')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }