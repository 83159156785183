var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t('components.stores.Withdrawals'))+" ")]),(_vm.$checkRole('sales_payments_add'))?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/storesWithdrawals/create?store_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.stores.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$t('storesWithdrawals.searchForAnstoresWithdrawalsOperations'),"emptyTableText":_vm.$t('storesWithdrawals.thereAreNostoresWithdrawalsOperations'),"filter":{ store_id: _vm.$route.params.id },"withoutCard":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
        {
          column: 'code',
          title: _vm.$t('storesWithdrawals.operation'),
          type: 'mainLink',
          sort: true
        },
        {
          column: 'store_id',
          title: _vm.$t('storesWithdrawals.storeName'),
          type: 'link',
          to: 'store',
          sort: true,
          link: true
        },
        {
          column: 'date',
          title: _vm.$t('storesWithdrawals.theDateOfTheOperation'),
          type: 'text',
          sort: true
        },
        {
          column: 'description',
          title: _vm.$t('storesWithdrawals.description'),
          type: 'text',
          sort: true
        },
        {
          column: 'options',
          title: _vm.$t('storesWithdrawals.settings'),
          type: 'options',
          options: [
            { name: 'show' },
            {
              name: 'edit',
              role: _vm.$checkRole('stores_withdrawals_edit')
            },
            { name: 'printStoresWithdrawal', role: true },
            {
              name: 'delete',
              role: _vm.$checkRole('stores_withdrawals_delete')
            }
          ]
        }
      ],"deleteText":{
        attention: _vm.$t('allerts.Attention'),
        areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
        close: _vm.$t('allerts.close'),
        confirm: _vm.$t('allerts.confirm'),
        loading: _vm.$t('allerts.loading')
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }