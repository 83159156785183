var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":"طلبات تحميل المخازن","description":"من هنا يمكنك التحكم في عمليات تحميل المخازن"}}),_c('indexTable',{attrs:{"searchText":_vm.$t('storesDeposits.searchForAnStoresDepositsOperations'),"emptyTableText":"لا يوجد عمليات تحميل مخازن !","emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$checkRole('stores_orders_show'),"statusSearch":true,"statusValues":[
          { title: _vm.$t('orders.situation'), value: '' },
          { title: _vm.$t('orders.all'), value: '' },
          { title: 'قيد الإنتظار', value: 0 },
          { title: 'محمل', value: 1 }
        ],"cloumns":[
          {
            column: 'code',
            title: _vm.$t('storesDeposits.operation'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'store_id',
            title: _vm.$t('storesDeposits.storeName'),
            type: 'link',
            to: 'store',
            sort: true,
            link: true
          },
          {
            column: 'date',
            title: _vm.$t('storesDeposits.theDateOfTheOperation'),
            type: 'text',
            sort: true
          },
          {
            column: 'description',
            title: _vm.$t('storesDeposits.description'),
            type: 'text',
            sort: true
          },
          {
            column: 'user_name',
            title: 'اسم المسئول',
            type: 'text',
            sort: true
          },
          {
            column: 'status',
            title: _vm.$t('bills.situation'),
            type: 'status',
            sort: true,
            values: [
              { title: 'قيدالإنتظار', value: 0, color: 'dark' },
              { title: 'محمل', value: 1, color: 'success' }
            ]
          },
          {
            column: 'options',
            title: _vm.$t('storesDeposits.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('stores_orders_edit')
              },
              {
                name: 'approveMoveStore',
                role: _vm.$checkRole('stores_transfers_add'),
                title: 'تأكيد التحميل',
                icon: 'suitcase'
              },

              {
                name: 'delete',
                role: _vm.$checkRole('stores_orders_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }