var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"icon":'treasure-chest',"title":_vm.item.name,"stat":_vm.item.stat,"statText":_vm.item.stat ? _vm.$t('safes.show.active') : _vm.$t('safes.show.inactive'),"subData":[
        {
          title: _vm.$t('safes.show.currentBalance'),
          value: this.$moneyFormat(_vm.item.balance)
        }
      ],"buttons":[
        {
          title: _vm.$t('safes.show.modulation'),
          icon: 'edit',
          link: _vm.path + '/' + _vm.$route.params.id + '/edit',
          role: _vm.$checkRole('safes_edit')
        },
        {
          title: _vm.$t('safes.show.TreasuryStatement'),
          icon: 'file-chart-pie',
          link: '/safesAccounts?safe_id=' + _vm.$route.params.id,
          role: _vm.$checkRole('reports_show')
        }
      ]}}),_c('showBoxs',{staticClass:"col-12",attrs:{"boxs":[
        {
          icon: 'dollar-sign',
          title: _vm.$t('safes.show.currentBalance'),
          value: _vm.$moneyFormat(_vm.item.balance),
          class: 'col-md-3'
        },
        {
          icon: 'credit-card-front',
          title: _vm.$t('safes.show.invoicesCount'),
          value: _vm.item.salesPaymentsCount,
          class: 'col-md-3'
        },
        {
          icon: 'credit-card-front',
          title: _vm.$t('safes.show.quotationsCount'),
          value: _vm.item.purchasePaymentsCount,
          class: 'col-md-3'
        },
        {
          icon: 'usd-square',
          title: _vm.$t('safes.show.ordersCount'),
          value: _vm.item.expensesCount,
          class: 'col-md-3'
        }
      ]}}),_c('showCard',{staticClass:"col-12",attrs:{"title":'عنوان الخزينة',"cloumns":{
        firstCloum: [
          {
            icon: 'map-marker-alt',
            title: _vm.$t('safes.show.Address'),
            value: _vm.item.address1
          },
          {
            icon: 'location-arrow',
            title: _vm.$t('safes.show.TheSide'),
            value: _vm.item.entity
          },
          {
            icon: 'map-marked',
            title: _vm.$t('safes.show.ZipCode'),
            value: _vm.item.zip
          },
          {
            icon: 'dollar-sign',
            title: _vm.$t('safes.show.Balance'),
            value: this.$moneyFormat(_vm.item.balance)
          }
        ],
        scondCloum: [
          {
            icon: 'map-marker-alt',
            title: _vm.$t('safes.show.TheSequel'),
            value: _vm.item.address2
          },
          { icon: 'city', title: _vm.$t('safes.show.City'), value: _vm.item.city },
          {
            icon: 'globe-americas',
            title: _vm.$t('safes.show.State'),
            value: _vm.item.country
          },
          {
            icon: 'dollar-sign',
            title: _vm.$t('safes.show.OpeningBalance'),
            value: this.$moneyFormat(_vm.item.opening_balance)
          }
        ]
      }}}),(_vm.$checkRole('sales_payments_show'))?_c('salesPayments',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$checkRole('purchase_payments_show'))?_c('purchasepayement',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$checkRole('sales_cheques_show'))?_c('salesCheques',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$checkRole('purchase_cheques_show'))?_c('purchaseCheques',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$checkRole('expenses_show'))?_c('expenses',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$checkRole('safes_deposits_show'))?_c('safesDeposits',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$checkRole('safes_withdrawals_show'))?_c('safesWithdrawals',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$checkRole('safes_transfers_show'))?_c('fromSafesTransfers',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$checkRole('safes_transfers_show'))?_c('toSafesTransfers',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$checkRole('employees_withdrawals_show'))?_c('employeesWithdrawals',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$checkRole('partners_deposits_show'))?_c('partnersDeposits',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$checkRole('partners_withdrawals_show'))?_c('partnersWithdrawals',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$checkRole('accounts_deposits_show'))?_c('accountsDeposits',{staticClass:"col-lg-12"}):_vm._e(),(_vm.$checkRole('accounts_withdrawals_show'))?_c('accountsWithdrawals',{staticClass:"col-lg-12"}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }