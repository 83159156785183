<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('partners.partners')"
          :description="$t('partners.FromHereYouCanControlYourPartnersAndFollowTheirAccounts')"
          :showExport="'true'"
          :exportLink="path + '/export/excel'"
          :showImport="'true'"
        />
        <indexTable
          :searchText="$t('partners.FindAPartner')"
          :emptyTableText="$t('partners.ThereAreNoPartners')"
          :emptyTableSubText="$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')"
          :buttonRole="$checkRole('partners_add')"
          :localData="true"
          :cloumns="[
            { column: 'name', title: $t('partners.PartnerName'), type: 'mainLink', sort: true },
            {
              column: 'mobile',
              title: $t('partners.PhoneNumber'),
              type: 'text',
              sort: true,
              icon: 'phone'
            },
            {
              column: 'balance',
              title: $t('partners.Balance'),
              type: 'text',
              sort: true,
              icon: 'usd-square'
            },

            {
              column: 'stat',
              title: $t('partners.Situation'),
              type: 'stat',
              sort: true,
              hideMobile: true
            },
            {
              column: 'options',
              title: $t('partners.Settings'),
              type: 'options',
              options: [
                { name: 'show' },
                { name: 'edit', role: $checkRole('partners_edit') },
                {
                  name: 'link',
                  role: $checkRole('partners_deposits_add'),
                  title: 'إيداع إلي شريك',
                  icon: 'donate',
                  link: '/partnersDeposits/create?partner_id='
                },
                {
                  name: 'link',
                  role: $checkRole('partners_withdrawals_add'),
                  title: 'سحب من شريك',
                  icon: 'money-bill-alt',
                  link: '/partnersWithdrawals/create?partner_id='
                },
                { name: 'delete', role: $checkRole('partners_delete') }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('allerts.Attention'),
            areYouReallySureToDeleteTheItem: $t(
              'allerts.AreYouReallySureAboutDeletingThePartnerIfYouDeleteThePartnerAllInvoicesAndProcessesWillBeDeleted'
            ),
            close: $t('allerts.close'),
            confirm: $t('allerts.confirm'),
            loading: $t('allerts.loading')
          }"
        />
      </div>
    </div>
    <importForm v-if="importShow"></importForm>
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'
import importForm from './components/importForm.vue'

export default {
  data() {
    return {
      path: '/partners',
      importShow: false
    }
  },
  methods: {
    importForm() {
      this.importShow = true
    }
  },
  components: {
    indexHeader,
    indexTable,
    importForm
  }
}
</script>
