<template>
  <div class="print-model">
    <div id="printMe" ref="printMe" v-if="!loading">
      <SafesWithdrawal :item="item" />
    </div>
  </div>
</template>
<script>
import SafesWithdrawal from './components/safesWithdrawals.vue'
export default {
  data() {
    return {
      logo: localStorage.getItem('logo'),
      item: {},
      loading: true
    }
  },
  methods: {
    getitem() {
      this.item = this.$parent.printedSafesWithdrawal
      this.loading = false
      var myel = this
      setTimeout(function () {
        var text = document.getElementById('printMe').innerHTML
        document.getElementById('printMe').remove()
        var myframe = document.getElementById('inviceIframe')
        myframe.contentDocument.body.innerHTML = text
        setTimeout(function () {
          myframe.focus()
          myframe.contentWindow.print()
          myel.colse()
        }, 100)
      }, 20)
    },
    colse() {
      this.$parent.printedSafesWithdrawal = false
    }
  },
  mounted() {
    this.getitem()
  },
  components: {
    SafesWithdrawal
  },
  props: ['options']
}
</script>
