var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":'التقارير',"description":'الرصيد المتبقي للموردين .'}}),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"exampleInputEmail1"}},[_vm._v("المدة الزمنية")]),_c('VueCtkDateTimePicker',{attrs:{"label":"اختر المدة الزمنية","button-now-translation":_vm.$t('reports.overview.now'),"format":"YYYY-MM-DD","formated":"YYYY-MM-DD","color":"#631263","button-color":"#631263","onlyDate":true},model:{value:(_vm.params.date),callback:function ($$v) {_vm.$set(_vm.params, "date", $$v)},expression:"params.date"}})],1)]),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"المورد","plaseholder":"اختر مورد","show":'name',"refrance":'id',"value":_vm.params.supplier_id,"values":_vm.suppliers},model:{value:(_vm.params.supplier_id),callback:function ($$v) {_vm.$set(_vm.params, "supplier_id", $$v)},expression:"params.supplier_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('reports.overview.sectionsupplier'),"plaseholder":_vm.$t('reports.overview.chooseSection'),"show":'name',"refrance":'id',"value":_vm.params.sections_account_id,"values":_vm.$database.sectionsAccounts},model:{value:(_vm.params.sections_account_id),callback:function ($$v) {_vm.$set(_vm.params, "sections_account_id", $$v)},expression:"params.sections_account_id"}}),_c('selectInput',{staticClass:"col-md-3",attrs:{"title":"الحالة","plaseholder":"اختر حالة","values":[
                { name: 'مدين', value: 0 },
                { name: 'دائن', value: 1 },
                { name: 'موقوف', value: 2 }
              ]},model:{value:(_vm.params.status),callback:function ($$v) {_vm.$set(_vm.params, "status", $$v)},expression:"params.status"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"الرصيد المتبقي للموردين","details":[
            { title: 'اسم المورد', value: _vm.supplierName },
            { title: 'الحالة', value: _vm.statusName },
            {
              title: _vm.$t('reports.overview.sectionsupplier'),
              value: _vm.sectionSupplierName
            }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":'لايوجد بيانات حاليا',"emptyTableSubText":'جرب اضافة البعض واعد المحاولة',"cloumns":[
            {
              column: 'name',
              link: '/suppliers',
              title: 'المورد',
              type: 'reportMainLink',
              sort: true
            },
            {
              column: 'balance',
              title: 'الرصيد',
              type: 'text',
              sort: 'true'
            }
          ],"footer":[
            { colspan: 1, value: 'المجموع' },
            { colspan: 1, value: _vm.balance }
          ]}})],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" خيارات التقرير ")])])
}]

export { render, staticRenderFns }