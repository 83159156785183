var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":'التقارير',"description":'الرصيد المتبقي الموظفين.'}}),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"الموظف","plaseholder":"اختر موظف","show":'name',"refrance":'id',"value":_vm.params.employee_id,"values":_vm.$database.employees},model:{value:(_vm.params.employee_id),callback:function ($$v) {_vm.$set(_vm.params, "employee_id", $$v)},expression:"params.employee_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"الرصيد المتبقي الموظفين","details":[{ title: 'اسم الموظف:', value: _vm.employeeName }]}}),_c('reportTable',{attrs:{"emptyTableText":'لايوجد بيانات حاليا',"emptyTableSubText":'جرب اضافة البعض واعد المحاولة',"cloumns":[
            {
              column: 'name',
              link: '/employees',
              title: 'اسم الموظف',
              type: 'reportMainLink',
              sort: true
            },
            {
              column: 'balance',
              title: 'الرصيد',
              type: 'text',
              sort: 'true'
            }
          ],"footer":[
            { colspan: 1, value: 'المجموع' },
            { colspan: 1, value: _vm.balance }
          ]}})],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" خيارات التقرير ")])])
}]

export { render, staticRenderFns }