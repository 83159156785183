import partnersIndex from '@/pages/partners/partners/index.vue'
import partnersForm from '@/pages/partners/partners/form.vue'
import partnersShow from '@/pages/partners/partners/show.vue'

import partnersDepositsIndex from '@/pages/partners/partnersDeposits/index.vue'
import partnersDepositsForm from '@/pages/partners/partnersDeposits/form.vue'
import partnersDepositsShow from '@/pages/partners/partnersDeposits/show.vue'

import partnersWithdrawalsIndex from '@/pages/partners/partnersWithdrawals/index.vue'
import partnersWithdrawalsForm from '@/pages/partners/partnersWithdrawals/form.vue'
import partnersWithdrawalsShow from '@/pages/partners/partnersWithdrawals/show.vue'

import partnersSettlementsIndex from '@/pages/partners/partnersSettlements/index.vue'
import partnersSettlementsForm from '@/pages/partners/partnersSettlements/form.vue'
import partnersSettlementsShow from '@/pages/partners/partnersSettlements/show.vue'

import partnersProfitsIndex from '@/pages/partners/partnersProfits/index.vue'
import partnersProfitsForm from '@/pages/partners/partnersProfits/form.vue'
import partnersProfitsShow from '@/pages/partners/partnersProfits/show.vue'

const routes = [
  // partners routes
  {
    path: 'partners',
    name: 'partners',
    component: partnersIndex
  },
  {
    path: 'partners/create',
    name: 'partnersCreate',
    component: partnersForm
  },
  {
    path: 'partners/:id/edit',
    name: 'partnersEdit',
    component: partnersForm
  },
  {
    path: 'partners/:id',
    name: 'partnersShow',
    component: partnersShow
  },

  // partners deposits routes
  {
    path: 'partnersDeposits',
    name: 'partnersDeposits',
    component: partnersDepositsIndex
  },
  {
    path: 'partnersDeposits/create',
    name: 'partnersDepositsCreate',
    component: partnersDepositsForm
  },
  {
    path: 'partnersDeposits/:id/edit',
    name: 'partnersDepositsEdit',
    component: partnersDepositsForm
  },
  {
    path: 'partnersDeposits/:id',
    name: 'partnersDepositsShow',
    component: partnersDepositsShow
  },

  // partners withdrawals routes
  {
    path: 'partnersWithdrawals',
    name: 'partnersWithdrawals',
    component: partnersWithdrawalsIndex
  },
  {
    path: 'partnersWithdrawals/create',
    name: 'partnersWithdrawalsCreate',
    component: partnersWithdrawalsForm
  },
  {
    path: 'partnersWithdrawals/:id/edit',
    name: 'partnersWithdrawalsEdit',
    component: partnersWithdrawalsForm
  },
  {
    path: 'partnersWithdrawals/:id',
    name: 'partnersWithdrawalsShow',
    component: partnersWithdrawalsShow
  },

  // partners settlements routes
  {
    path: 'partnersSettlements',
    name: 'partnersSettlements',
    component: partnersSettlementsIndex
  },
  {
    path: 'partnersSettlements/create',
    name: 'partnersSettlementsCreate',
    component: partnersSettlementsForm
  },
  {
    path: 'partnersSettlements/:id/edit',
    name: 'partnersSettlementsEdit',
    component: partnersSettlementsForm
  },
  {
    path: 'partnersSettlements/:id',
    name: 'partnersSettlementsShow',
    component: partnersSettlementsShow
  },

  // partners profits routes
  {
    path: 'partnersProfits',
    name: 'partnersProfits',
    component: partnersProfitsIndex
  },
  {
    path: 'partnersProfits/create',
    name: 'partnersProfitsCreate',
    component: partnersProfitsForm
  },
  {
    path: 'partnersProfits/:id/edit',
    name: 'partnersProfitsEdit',
    component: partnersProfitsForm
  },
  {
    path: 'partnersProfits/:id',
    name: 'partnersProfitsShow',
    component: partnersProfitsShow
  }
]

export default routes
