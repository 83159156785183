var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('bankAccounts.bankAccounts'),"description":_vm.$t('bankAccounts.bankAccountsDescription'),"explanationPath":_vm.path + '/explaination'}}),_c('indexTable',{attrs:{"searchText":_vm.$t('bankAccounts.searchForAccounts'),"emptyTableText":_vm.$t('bankAccounts.thereAreNoAccounts'),"buttonRole":_vm.$checkRole('bank_accounts_add'),"localData":true,"filter":{ bank_account: 1 },"table":'safes',"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
          {
            column: 'name',
            title: _vm.$t('bankAccounts.bankName'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'account_number',
            title: _vm.$t('bankAccounts.accountNumber'),
            type: 'text',
            sort: true,
            icon: 'credit-card-front'
          },
          {
            column: 'bank_name',
            title: _vm.$t('bankAccounts.accountName'),
            type: 'text',
            sort: true,
            icon: ' fa-user'
          },
          {
            column: 'balance',
            title: _vm.$t('bankAccounts.balance'),
            type: 'text',
            sort: true,
            icon: 'usd-square'
          },
          {
            column: 'stat',
            title: _vm.$t('bankAccounts.active'),
            type: 'stat',
            sort: true,
            hideMobile: true
          },
          {
            column: 'options',
            title: _vm.$t('bankAccounts.actions'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('bank_accounts_edit')
              },
              {
                name: 'link',
                role: _vm.$checkRole('safes_transfers_add'),
                title: _vm.$t('bankAccounts.TransferFromTheTreasury'),
                icon: 'suitcase',
                link: '/safesTransfers/create?from_safe_id='
              },
              {
                name: 'link',
                role: _vm.$checkRole('safes_deposits_add'),
                title: _vm.$t('bankAccounts.DepositToTheSafe'),
                icon: 'donate',
                link: '/safesDeposits/create?safe_id='
              },
              {
                name: 'link',
                role: _vm.$checkRole('safes_withdrawals_add'),

                title: _vm.$t('bankAccounts.WithdrawalFromTheTreasury'),
                icon: 'money-bill-alt',
                link: '/safesWithdrawals/create?safe_id='
              },
              {
                name: 'delete',
                role: _vm.$checkRole('bank_accounts_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }