var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('incomes.incomes'),"description":_vm.$t('incomes.fromHereYouCanControlTheIncomes')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('incomes.SearchForAllowance'),"emptyTableText":_vm.$t('incomes.thereAreNoIncomes'),"buttonRole":_vm.$checkRole('expenses_add'),"statusSearch":true,"statusValues":[
          { title: _vm.$t('orders.situation'), value: '' },
          { title: _vm.$t('orders.all'), value: '' },
          { title: _vm.$t('invoices.draft'), value: 0 },
          { title: _vm.$t('invoices.approved'), value: 1 }
        ],"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
          {
            column: 'code',
            title: _vm.$t('incomes.PerDiem'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'safe_id',
            title: _vm.$t('incomes.Treasury'),
            type: 'link',
            to: 'safe',
            sort: true,
            link: true
          },
          {
            column: 'date',
            title: _vm.$t('incomes.incomeDate'),
            type: 'text',
            sort: true
          },
          {
            column: 'income_section_id',
            title: _vm.$t('incomes.section'),
            type: 'link',
            to: 'incomeSection',
            sort: true,
            link: false
          },
          {
            column: 'description',
            title: _vm.$t('incomes.thatsAbout'),
            type: 'text',
            sort: true
          },
          {
            column: 'notes',
            title: _vm.$t('incomes.note'),
            type: 'text',
            sort: true
          },
          {
            column: 'cost',
            title: _vm.$t('incomes.amount'),
            type: 'text',
            sort: true
          },
          {
            column: 'type',
            title: _vm.$t('incomes.form.type'),
            type: 'status',
            sort: true,
            values: [
              { title: _vm.$t('incomes.form.paid'), value: 0, color: 'success' },
              { title: _vm.$t('incomes.form.worthy'), value: 1, color: 'dark' }
            ]
          },
          {
            column: 'user_name',
            title: 'اسم المسئول',
            type: 'text',
            sort: true
          },
          {
            column: 'status',
            title: _vm.$t('bills.situation'),
            type: 'status',
            sort: true,
            values: [
              { title: _vm.$t('invoices.draft'), value: 0, color: 'dark' },
              { title: _vm.$t('invoices.approved'), value: 1, color: 'success' }
            ]
          },
          {
            column: 'options',
            title: _vm.$t('incomes.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              { name: 'edit', role: _vm.$checkRole('expenses_edit') },
              { name: 'file' },
              { name: 'printIncome', role: true },
              { name: 'download', role: true },
              {
                name: 'delete',
                role: _vm.$checkRole('expenses_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }