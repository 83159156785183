<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('paymentFees.paymentFees')"
          :description="$t('paymentFees.fromHereYouCanControlThePaymentFees')"
        />
        <indexTable
          :searchText="$t('paymentFees.SearchForAFee')"
          :emptyTableText="$t('paymentFees.thereIsNoPaymentFee')"
          :localData="true"
          :emptyTableSubText="$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')"
          :buttonRole="
            $checkRole('sales_payments_add') ||
            $checkRole('purchase_payments_add') ||
            $checkRole('expenses_add')
          "
          :cloumns="[
            {
              column: 'name',
              title: $t('paymentFees.paymentFeeName'),
              type: 'text',
              sort: true
            },
            {
              column: 'cost',
              title: $t('paymentFees.paymentFeeCost'),
              type: 'text',
              sort: true
            },
            {
              column: 'options',
              title: $t('paymentFees.actions'),
              type: 'options',
              options: [
                {
                  name: 'edit',
                  role:
                    $user.admin ||
                    $checkRole('sales_payments_edit') ||
                    $checkRole('purchase_payments_edit') ||
                    $checkRole('expenses_edit')
                },
                {
                  name: 'delete',
                  role:
                    $user.admin ||
                    $checkRole('sales_payments_delete') ||
                    $checkRole('purchase_payments_delete') ||
                    $checkRole('expenses_delete')
                }
              ]
            }
          ]"
          :deleteText="{
            attention: $t('allerts.Attention'),
            areYouReallySureToDeleteTheItem: $t('allerts.areYouReallySureToDeleteTheItem'),
            close: $t('allerts.close'),
            confirm: $t('allerts.confirm'),
            loading: $t('allerts.loading')
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import indexHeader from '@/elements/index/indexHeader.vue'
import indexTable from '@/elements/index/indexTable.vue'
export default {
  data() {
    return {
      path: '/paymentFees'
    }
  },
  components: {
    indexHeader,
    indexTable
  }
}
</script>
