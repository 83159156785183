var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row container-fluid"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('mainReport.partners'),"description":_vm.$t('mainReport.Fromhereyoucancontrolyourpartnersreports')}})],1),(
      (_vm.$user.role.available_reports.find(
        (el) => el.id == 34 || el.id == 35 || el.id == 36 || el.id == 37
      ) ||
        _vm.$user.admin) &&
      _vm.$site.partners_allow
    )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.partners'),"icon":'fa-handshake',"reports":[
      {
        name: _vm.$t('mainReport.TotalPartnerAccount'),
        link: './partnersTotalAccounts',
        role: _vm.$user.role.available_reports.find((el) => el.id == 34) || _vm.$user.admin
      },
      {
        name: _vm.$t('mainReport.PartnerAccountDetails'),
        link: '/partnersDetailsAccounts',
        role: _vm.$user.role.available_reports.find((el) => el.id == 35) || _vm.$user.admin
      },
      {
        name: _vm.$t('mainReport.PartnersAssets'),
        link: '/partnersBalance',
        role: _vm.$user.role.available_reports.find((el) => el.id == 36) || _vm.$user.admin
      },
      {
        name: _vm.$t('mainReport.PartnerGuide'),
        link: '/partnersGuide',
        role: _vm.$user.role.available_reports.find((el) => el.id == 37) || _vm.$user.admin
      },
      {
        name: _vm.$t('mainReport.partnerMovement'),
        link: '/partnerMovement',
        role: _vm.$user.role.available_reports.find((el) => el.id == 37) || _vm.$user.admin
      }
    ]}}):_vm._e(),(
      (_vm.$user.role.available_reports.find((el) => el.id == 31 || el.id == 32) || _vm.$user.admin) &&
      (_vm.$site.partnersDeposits_allow ||
        _vm.$site.partnersWithdrawals_allow ||
        _vm.$site.partnersSettlements_allow ||
        _vm.$site.partnersProfits_allow)
    )?_c('reportsCard',{staticClass:"col-md-6",attrs:{"cardTitle":_vm.$t('mainReport.PartnersOperations'),"icon":'fa-handshake',"reports":[
      {
        name: _vm.$t('mainReport.PartnerDepositsHistory'),
        link: './partnerDepositsHistory',
        role: _vm.$user.role.available_reports.find((el) => el.id == 31) || _vm.$user.admin
      },
      {
        name: _vm.$t('mainReport.PartnerWithdrawalsHistory'),
        link: '/partnerWithdrawalsHistory',
        role: _vm.$user.role.available_reports.find((el) => el.id == 32) || _vm.$user.admin
      }
    ]}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }