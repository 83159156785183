var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":'التقارير',"description":'سجل نقل الخزائن'}}),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('dateFilter',{staticClass:"col-md-3"}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"المستخدم","plaseholder":"اختر مستخدم","show":'name',"refrance":'id',"value":_vm.params.user_id,"values":this.users},model:{value:(_vm.params.user_id),callback:function ($$v) {_vm.$set(_vm.params, "user_id", $$v)},expression:"params.user_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"من خزينة","plaseholder":"اختر خزينة","show":'name',"refrance":'id',"value":_vm.params.from_safe_id,"values":_vm.$database.safes.filter((el) => el.id != _vm.params.to_safe_id)},model:{value:(_vm.params.from_safe_id),callback:function ($$v) {_vm.$set(_vm.params, "from_safe_id", $$v)},expression:"params.from_safe_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"الي خزينة","plaseholder":"اختر خزينة","show":'name',"refrance":'id',"value":_vm.params.to_safe_id,"values":_vm.$database.safes.filter((el) => el.id != _vm.params.from_safe_id)},model:{value:(_vm.params.to_safe_id),callback:function ($$v) {_vm.$set(_vm.params, "to_safe_id", $$v)},expression:"params.to_safe_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"سجل نقل الخزائن","details":[
            { title: 'اسم المستخدم', value: _vm.userName },
            { title: 'من خزينة', value: _vm.fromSafeName },
            { title: 'الي خزينة', value: _vm.toSafeName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":'لايوجد بيانات حاليا',"emptyTableSubText":'جرب اضافة البعض واعد المحاولة',"cloumns":[
            {
              column: 'code',
              link: '/safesTransfers',
              title: 'الكود',
              type: 'reportMainLink',
              sort: true
            },
            { column: 'date', title: 'التاريخ', type: 'text', sort: true },
            { column: 'user', title: 'المستخدم', type: 'text', sort: true },
            {
              column: 'from_safe_id',
              title: 'من خزينة',
              element: 'fromSafe',
              type: 'link',
              to: 'safe',
              sort: true,
              link: true
            },
            {
              column: 'to_safe_id',
              title: 'الي خزينة',
              element: 'toSafe',
              type: 'link',
              to: 'safe',
              sort: true,
              link: true
            },
            { column: 'cost', title: 'المبلغ', type: 'text', sort: true }
          ],"footer":[
            { colspan: 5, value: 'المجموع' },
            { colspan: 1, value: _vm.cost }
          ]}})],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" خيارات التقرير ")])])
}]

export { render, staticRenderFns }