var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.$t('components.safes.expenses')))]),(_vm.$parent.item.stat == 1 && _vm.$checkRole('sales_payments_add'))?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/expenses/create?safe_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.safes.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$t('components.safes.searchForMoney'),"emptyTableText":_vm.$t('expenses.thereAreNoExpenses'),"filter":{ safe_id: _vm.$route.params.id },"withoutCard":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
        {
          column: 'code',
          title: _vm.$t('components.safes.theExpense'),
          type: 'mainLink',
          sort: true
        },
        {
          column: 'safe_id',
          title: _vm.$t('expenses.Treasury'),
          type: 'link',
          to: 'safe',
          sort: true,
          link: true
        },
        {
          column: 'date',
          title: _vm.$t('expenses.expenseDate'),
          type: 'text',
          sort: true
        },
        {
          column: 'expense_section_id',
          title: _vm.$t('expenses.section'),
          type: 'link',
          to: 'expenseSection',
          sort: true,
          link: false
        },
        {
          column: 'description',
          title: _vm.$t('expenses.thatsAbout'),
          type: 'text',
          sort: true
        },
        {
          column: 'notes',
          title: _vm.$t('expenses.note'),
          type: 'text',
          sort: true
        },
        {
          column: 'cost',
          title: _vm.$t('expenses.amount'),
          type: 'text',
          sort: true
        },

        {
          column: 'options',
          title: _vm.$t('expenses.settings'),
          type: 'options',
          options: [
            { name: 'show' },
            {
              name: 'edit',
              role: _vm.$checkRole('expenses_edit')
            },
            { name: 'print', role: true },
            { name: 'download', role: true },
            {
              name: 'delete',
              role: _vm.$checkRole('expenses_delete')
            }
          ]
        }
      ],"deleteText":{
        attention: _vm.$t('allerts.Attention'),
        areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
        close: _vm.$t('allerts.close'),
        confirm: _vm.$t('allerts.confirm'),
        loading: _vm.$t('allerts.loading')
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }