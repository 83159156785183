<template>
  <div id="printMe" ref="printMe" v-if="!loading">
    <div
      class="inv-design"
      :class="$option.print_type == 3 ? 'inv-design-5cm' : ''"
      v-if="$option.print_type == 1 || $option.print_type == 3"
    >
      <img :src="logo" class="img-fluid mb-4 sm-inv-img" v-if="$option.image != null" />
      <div class="text-center">
        <p class="f-14">{{ $option.name }}</p>
        <p class="f-12">{{ $option.address1 }}</p>
        <p class="f-14">{{ item.code }}</p>
        <div style="display: flex">
          <div style="width: 4cm; text-align: right">
            <p class="f-12">
              التاريخ : <strong>{{ $dateFormat(item.date) }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div>
        <hr />
        <div style="text-align: center" v-if="item.client_type == 0">
          <p class="f-12" v-if="item.customer.name">
            اسم العميل: <strong>{{ item.customer.name }}</strong>
          </p>
          <p class="f-12" v-if="item.customer.tax_number">
            المعرف الضريبي: <strong>{{ item.customer.tax_number }}</strong>
          </p>
          <p class="f-12" v-if="item.customer.city || item.customer.country">
            العنوان:
            <strong>
              {{ item.customer.city }}{{ item.customer.city && item.customer.country ? ',' : '' }}
              {{ item.customer.country }}</strong
            >
          </p>
          <p class="f-12" v-if="item.customer.mobile">
            رقم الهاتف : <strong>{{ item.customer.mobile }}</strong>
          </p>
        </div>
        <div style="text-align: center" v-if="item.client_type == 1">
          <p class="f-12" v-if="item.supplier.name">
            اسم العميل: <strong>{{ item.supplier.name }}</strong>
          </p>
          <p class="f-12" v-if="item.supplier.tax_number">
            المعرف الضريبي: <strong>{{ item.supplier.tax_number }}</strong>
          </p>
          <p class="f-12" v-if="item.supplier.city || item.supplier.country">
            العنوان:
            <strong>
              {{ item.supplier.city }}{{ item.supplier.city && item.supplier.country ? ',' : '' }}
              {{ item.supplier.country }}</strong
            >
          </p>
          <p class="f-12" v-if="item.supplier.mobile">
            رقم الهاتف : <strong>{{ item.supplier.mobile }}</strong>
          </p>
        </div>
        <hr />
      </div>
      <div class="table-responsive">
        <table class="table my-4">
          <tfoot>
            <tr>
              <td class="px-0">
                <strong>الرصيد السابق</strong>
              </td>
              <td class="px-0">
                <span class="h3">
                  {{ $moneyFormat(item.old_balance) }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="px-0">
                <strong>المدفوع</strong>
              </td>
              <td class="px-0">
                <span class="h3">
                  {{ $moneyFormat(item.cost) }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="px-0">
                <strong>الرصيد النهائي</strong>
              </td>
              <td class="px-0">
                <span class="h3">
                  {{ $moneyFormat(item.new_balance) }}
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
        <p
          class="text-muted mb-0"
          v-html="
            $option.sales_payments_print_footer != null ? $option.sales_payments_print_footer : ''
          "
        ></p>
      </div>
    </div>

    <div class="row invoice-show hide-w-8" v-if="$option.print_type == 0">
      <div class="col-12"></div>
    </div>
    <div class="col text-center" v-if="$option.print_type == 0">
      <div class="row">
        <img
          :src="logo"
          class="img-fluid mb-4"
          style="max-width: 4.5rem"
          v-if="$option.image != null"
        />
        <h2 class="mb-2">سند قبض ل {{ $option.name }}</h2>
        <p class="text-muted mb-6">سند قبض #{{ item.code }}</p>
      </div>
    </div>
    <div class="row" v-if="$option.print_type == 0">
      <table class="table table-sm table-nowrap customed-table-small">
        <tbody>
          <tr>
            <th scope="row">رقم السند#</th>
            <td>{{ item.code != null ? item.code : '--' }}</td>
            <td rowspan="7" style="background: #eee; text-align: center; padding-top: 120px">
              <div class="price-show" style="margin-top: 20px; padding-top: 30px">
                <div class="price-content">
                  <p>{{ $t('expenses.form.cost') }}</p>
                  <h1>{{ $moneyFormat(item.cost) }}</h1>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">{{ $t('expenses.form.date') }}</th>
            <td>{{ $dateFormat(item.date) }}</td>
          </tr>
          <tr>
            <th scope="row">مسئول إداري</th>
            <td>{{ item.user != null ? item.user.name : '--' }}</td>
          </tr>
          <tr>
            <th scope="row">الرقم المرجعي</th>
            <td>{{ item.refrance != null ? item.refrance : '--' }}</td>
          </tr>
          <tr>
            <th scope="row">تلقي من</th>
            <td>{{ item.customer.id != null ? item.customer.name : '--' }}</td>
          </tr>
          <tr>
            <th scope="row">وذالك عن</th>
            <td scope="row">{{ item.description }}</td>
          </tr>
          <tr>
            <th scope="row">الخزينة</th>
            <td>{{ item.safe.id != null ? item.safe.name : '--' }}</td>
          </tr>
        </tbody>
      </table>

      <div class="signeture-container">
        <div
          class="signeture-item"
          v-for="(signeture, index) in $option.sales_payments_signatures_data"
          :key="index"
        >
          <div class="signeture-name">{{ signeture.name }}</div>
          <div class="signeture-points">..........................</div>
        </div>
      </div>
    </div>
    <p class="pioneer-data">تم الإنشاء بواسطة Pioneer للبرمجيات 01019654321</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logo: localStorage.getItem('logo')
    }
  },
  props: {
    item: {}
  }
}
</script>
