<template>
  <div class="print-model">
    <div id="printMe" ref="printMe">
      <div v-for="(item, index) in $parent.options.quotationItems" :key="index">
        <div v-for="(i, index) in parseFloat(item.quantity > 0 ? item.quantity : 0)" :key="index">
          <div
            class="sticer2"
            :style="
              'height: ' +
              $parent.options.height +
              'mm;width: ' +
              $parent.options.width +
              'mm;text-align:center;font-weight:900;padding: ' +
              $parent.options.height_padding +
              'mm ' +
              $parent.options.width_padding +
              'mm;box-sizing: border-box;color: black;'
            "
          >
            <div class="product-name" style="font-size: 14px">
              {{ item.product_code ? item.product_code : '--' }} <br />
              {{ item.product_name ? item.product_name : '--' }}
              <!-- <br />
              {{ item.barcode_description ? item.barcode_description : '--' }} -->
            </div>
            <barcode
              :value="item.barcode"
              font-size="0"
              :height="
                ($parent.options.height - $parent.options.height_padding * 2) * 3.7795275591 -
                70 +
                'px'
              "
              width="2px"
              :style="
                'margin-top: 2px;margin-bottom: 2px;height: ' +
                (($parent.options.height - $parent.options.height_padding * 2) * 3.7795275591 -
                  50) +
                'px'
              "
              margin-top="-4"
              margin-bottom="-4"
              class="barcode"
            />
            <div
              :style="'letter-spacing: 5px;font-size: 14px;margin-bottom: -3px;margin-top: -24px;'"
            >
              {{ item.barcode }}
            </div>
            <div class="product-price" v-if="$option.barcode_company_show" style="font-size: 13px">
              {{ $option.name }}
            </div>

            <div class="product-price" v-if="$option.barcode_price_show" style="font-size: 12px">
              {{ item.price }} {{ $option.currency }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueBarcode from 'vue-barcode'
export default {
  data() {
    return {
      logo: localStorage.getItem('logo')
    }
  },
  components: {
    barcode: VueBarcode
  }
}
</script>
