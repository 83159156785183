var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t('components.suppliers.purchaseDiscounts'))+" ")]),(_vm.$checkRole('sales_payments_add'))?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/purchaseDiscounts/create?supplier_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.suppliers.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$t('components.suppliers.searchForACheck'),"withoutCard":true,"emptyTableText":_vm.$t('salesDiscounts.thereAreNosalesDiscounts'),"filter":{ supplier_id: _vm.$route.params.id },"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
        {
          column: 'code',
          title: _vm.$t('components.suppliers.rival'),
          type: 'mainLink',
          sort: true
        },
        {
          column: 'client_type',
          title: _vm.$t('components.suppliers.supplierName'),
          type: 'clintLink',
          sort: true
        },
        {
          column: 'date',
          title: _vm.$t('salesDiscounts.date'),
          type: 'text',
          sort: true
        },
        {
          column: 'description',
          title: _vm.$t('salesDiscounts.thatsAbout'),
          type: 'text',
          sort: true
        },
        {
          column: 'cost',
          title: _vm.$t('salesDiscounts.amount'),
          type: 'text',
          sort: true
        },
        {
          column: 'options',
          title: _vm.$t('salesDiscounts.settings'),
          type: 'options',
          options: [
            { name: 'show' },
            {
              name: 'edit',
              role: _vm.$checkRole('sales_payments_edit')
            },
            {
              name: 'delete',
              role: _vm.$checkRole('sales_payments_delete')
            }
          ]
        }
      ],"deleteText":{
        attention: _vm.$t('allerts.Attention'),
        areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
        close: _vm.$t('allerts.close'),
        confirm: _vm.$t('allerts.confirm'),
        loading: _vm.$t('allerts.loading')
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }