<template>
  <div class="modal fade show">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="sm-stop-edit" v-if="loading"></div>
        <div class="modal-card card">
          <div class="card-header" style="background: none">
            <h4 class="card-header-title">تحميل من مخزن</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <connectionInput
                col="col-12"
                title="المخزن المنقول منه المنتحات "
                v-model="store"
                :values="$database.stores.filter((el) => el.id != $parent.not_id)"
              />
            </div>
          </div>
          <div class="card-footer card-footer-boxed">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto"></div>
              <div class="col-auto">
                <!-- Link -->
                <button class="btn btn-outline-danger" @click="colse()">
                  <i class="far fa-times"></i>
                  اغلاق
                </button>
                <button @click="changeStatus()" class="btn btn-dark" v-if="!loading">
                  <i class="far fa-check"></i>
                  تحميل
                </button>
                <button class="btn btn-dark" v-if="loading">
                  جاري التحميل <span class="loading-s1 sm-j">.</span
                  ><span class="loading-s2 sm-j">.</span><span class="loading-s3 sm-j">.</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import connectionInput from '@/elements/add/form/connectionInput.vue'
export default {
  data() {
    return {
      loading: false,
      store: null
    }
  },
  methods: {
    colse() {
      this.$parent.approve_move_store_id = null
    },
    changeStatus() {
      this.$parent.doApproveMoveStore(this.store)
      this.loading = true
    }
  },
  components: {
    connectionInput
  }
}
</script>
