var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":'التقارير',"description":'نقل بالفاتورة.'}}),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('dateFilter',{staticClass:"col-md-3"}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"من مخزن","plaseholder":"اختر مخزن","show":'name',"refrance":'id',"value":_vm.params.from_store_id,"values":_vm.$database.stores},model:{value:(_vm.params.from_store_id),callback:function ($$v) {_vm.$set(_vm.params, "from_store_id", $$v)},expression:"params.from_store_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"إلي مخزن","plaseholder":"اختر مخزن","show":'name',"refrance":'id',"value":_vm.params.to_store_id,"values":_vm.$database.stores},model:{value:(_vm.params.to_store_id),callback:function ($$v) {_vm.$set(_vm.params, "to_store_id", $$v)},expression:"params.to_store_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"المستخدم","plaseholder":"اختر مستخدم","show":'name',"refrance":'id',"value":_vm.params.user_id,"values":this.users},model:{value:(_vm.params.user_id),callback:function ($$v) {_vm.$set(_vm.params, "user_id", $$v)},expression:"params.user_id"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"إيداع المخازن بالفاتورة","details":[
            { title: 'من مخزن', value: _vm.fromStoreName },
            { title: 'إلى مخزن', value: _vm.toStoreName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":'لايوجد بيانات حاليا',"emptyTableSubText":'جرب اضافة البعض واعد المحاولة',"cloumns":[
            {
              column: 'code',
              link: '/storesDesposits',
              title: 'الكود',
              type: 'reportMainLink',
              sort: true
            },
            {
              column: 'date',
              title: 'التاريخ',
              type: 'text',
              sort: true,
              supData: true
            },

            {
              column: 'from_store_name',
              title: _vm.$t('storesDeposits.storeName'),
              type: 'text'
            },
            {
              column: 'to_store_name',
              title: _vm.$t('storesDeposits.storeName'),
              type: 'text'
            },

            {
              column: 'user',
              title: 'المستخدم',
              type: 'text',
              sort: true,
              supData: true
            }
          ],"footer":[
            { colspan: 4, value: 'المجموع' },
            { colspan: 1, value: _vm.invoicesCount, supData: true }
          ]}})],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" خيارات التقرير")])])
}]

export { render, staticRenderFns }