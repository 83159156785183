var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":'التقارير',"description":'سحوبات الموظفين.'}}),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"الموظف","plaseholder":"اختر موظف","show":'name',"refrance":'id',"value":_vm.params.employee_id,"values":_vm.$database.employees},model:{value:(_vm.params.employee_id),callback:function ($$v) {_vm.$set(_vm.params, "employee_id", $$v)},expression:"params.employee_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"اسم المستخدم","plaseholder":"اختر اسم المستخدم","show":'name',"refrance":'id',"value":_vm.params.user_id,"values":_vm.users},model:{value:(_vm.params.user_id),callback:function ($$v) {_vm.$set(_vm.params, "user_id", $$v)},expression:"params.user_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"سحوبات الموظفين","details":[
            { title: 'اسم الموظف', value: _vm.employeeName },
            { title: 'اسم المستخدم', value: _vm.userName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":'لايوجد بيانات حاليا',"emptyTableSubText":'جرب اضافة البعض واعد المحاولة',"cloumns":[
            {
              column: 'code',
              title: _vm.$t('employeesWithdrawals.Withdraw'),
              type: 'mainLink',
              sort: true
            },
            {
              column: 'employee_id',
              title: _vm.$t('employeesDeductions.functionary'),
              type: 'link',
              to: 'employee',
              sort: true,
              link: true
            },
            {
              column: 'user_id',
              title: _vm.$t('mainReport.userName'),
              type: 'link',
              to: 'user',
              sort: true,
              link: false
            },
            {
              column: 'date',
              title: _vm.$t('employeesDeductions.date'),
              type: 'text',
              sort: true
            },
            {
              column: 'description',
              title: _vm.$t('employeesDeductions.thatsAbout'),
              type: 'text',
              sort: true
            },
            {
              column: 'notes',
              title: _vm.$t('employeesDeductions.note'),
              type: 'text',
              sort: true
            },
            {
              column: 'cost',
              title: _vm.$t('employeesDeductions.cost'),
              type: 'text',
              sort: true
            }
          ],"footer":[
            { colspan: 6, value: 'المجموع' },
            { colspan: 1, value: _vm.cost }
          ]}})],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" خيارات التقرير ")])])
}]

export { render, staticRenderFns }