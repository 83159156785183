<template>
  <div v-if="isSelected">
    <slot></slot>
  </div>
</template>
<script>
import roleGroupInput from '@/elements/add/form/roleGroupInput.vue'
export default {
  props: {
    isSelected: {
      type: Boolean
    }
  },
  components: {
    roleGroupInput
  }
}
</script>
