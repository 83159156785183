var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('employees.employees'),"description":_vm.$t('employees.fromHereYouCanControlEmployees')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('employees.FindAnEmployee'),"emptyTableText":_vm.$t('employees.thereAreNoEmployeess'),"localData":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$checkRole('employees_add'),"cloumns":[
          {
            column: 'name',
            title: _vm.$t('employees.name'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'job_title',
            title: _vm.$t('employees.JobTitle'),
            type: 'text',
            sort: true,
            icon: 'user-tie'
          },
          {
            column: 'salary',
            title: _vm.$t('employees.salary'),
            type: 'text',
            sort: true,
            icon: 'coins'
          },
          {
            column: 'balance',
            title: _vm.$t('employees.Balance'),
            type: 'text',
            sort: true,
            icon: 'usd-square'
          },
          {
            column: 'stat',
            title: _vm.$t('employees.active'),
            type: 'stat',
            sort: true,
            hideMobile: true
          },
          {
            column: 'options',
            title: _vm.$t('employees.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('employees_edit')
              },
              {
                name: 'link',
                role: _vm.$checkRole('employees_edit'),
                title: _vm.$t('employees.AddASalary'),
                icon: 'money-check-alt',
                link: '/employeesSalaries/create?employee_id='
              },
              {
                name: 'link',
                role: _vm.$checkRole('employees_edit'),
                title: _vm.$t('employees.incentive'),
                icon: 'hand-holding-usd',
                link: '/employeesIncentives/create?employee_id='
              },
              {
                name: 'link',
                role: _vm.$checkRole('employees_edit'),
                title: _vm.$t('employees.deduct'),
                icon: 'funnel-dollar',
                link: '/employeesDeductions/create?employee_id='
              },
              {
                name: 'link',
                role: _vm.$checkRole('employees_edit'),
                title: _vm.$t('employees.BalanceWithdrawal'),
                icon: 'dollar-sign',
                link: '/employeesWithdrawals/create?employee_id='
              },
              {
                name: 'delete',
                role: _vm.$checkRole('employees_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }