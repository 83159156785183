import employeesIndex from '@/pages/employees/employees/index.vue'
import employeesForm from '@/pages/employees/employees/form.vue'
import employeesShow from '@/pages/employees/employees/show.vue'

import employeesSalariesIndex from '@/pages/employees/employeesSalaries/index.vue'
import employeesSalariesForm from '@/pages/employees/employeesSalaries/form.vue'
import employeesSalariesShow from '@/pages/employees/employeesSalaries/show.vue'

import employeesIncentivesIndex from '@/pages/employees/employeesIncentives/index.vue'
import employeesIncentivesForm from '@/pages/employees/employeesIncentives/form.vue'
import employeesIncentivesShow from '@/pages/employees/employeesIncentives/show.vue'

import employeesDeductionsIndex from '@/pages/employees/employeesDeductions/index.vue'
import employeesDeductionsForm from '@/pages/employees/employeesDeductions/form.vue'
import employeesDeductionsShow from '@/pages/employees/employeesDeductions/show.vue'

import employeesWithdrawalsIndex from '@/pages/employees/employeesWithdrawals/index.vue'
import employeesWithdrawalsForm from '@/pages/employees/employeesWithdrawals/form.vue'
import employeesWithdrawalsShow from '@/pages/employees/employeesWithdrawals/show.vue'

const routes = [
  {
    path: 'employees',
    name: 'employees',
    component: employeesIndex
  },
  {
    path: 'employees/create',
    name: 'employeesCreate',
    component: employeesForm
  },
  {
    path: 'employees/:id/edit',
    name: 'employeesEdit',
    component: employeesForm
  },
  {
    path: 'employees/:id',
    name: 'employeesShow',
    component: employeesShow
  },

  //employees salaries
  {
    path: 'employeesSalaries',
    name: 'employeesSalaries',
    component: employeesSalariesIndex
  },
  {
    path: 'employeesSalaries/create',
    name: 'employeesSalariesCreate',
    component: employeesSalariesForm
  },
  {
    path: 'employeesSalaries/:id/edit',
    name: 'employeesSalariesEdit',
    component: employeesSalariesForm
  },
  {
    path: 'employeesSalaries/:id',
    name: 'employeesSalariesShow',
    component: employeesSalariesShow
  },

  //employees incentives
  {
    path: 'employeesIncentives',
    name: 'employeesIncentives',
    component: employeesIncentivesIndex
  },
  {
    path: 'employeesIncentives/create',
    name: 'employeesIncentivesCreate',
    component: employeesIncentivesForm
  },
  {
    path: 'employeesIncentives/:id/edit',
    name: 'employeesIncentivesEdit',
    component: employeesIncentivesForm
  },
  {
    path: 'employeesIncentives/:id',
    name: 'employeesIncentivesShow',
    component: employeesIncentivesShow
  },

  //employees deductions
  {
    path: 'employeesDeductions',
    name: 'employeesDeductions',
    component: employeesDeductionsIndex
  },
  {
    path: 'employeesDeductions/create',
    name: 'employeesDeductionsCreate',
    component: employeesDeductionsForm
  },
  {
    path: 'employeesDeductions/:id/edit',
    name: 'employeesDeductionsEdit',
    component: employeesDeductionsForm
  },
  {
    path: 'employeesDeductions/:id',
    name: 'employeesDeductionsShow',
    component: employeesDeductionsShow
  },

  //employees withdrawals
  {
    path: 'employeesWithdrawals',
    name: 'employeesWithdrawals',
    component: employeesWithdrawalsIndex
  },
  {
    path: 'employeesWithdrawals/create',
    name: 'employeesWithdrawalsCreate',
    component: employeesWithdrawalsForm
  },
  {
    path: 'employeesWithdrawals/:id/edit',
    name: 'employeesWithdrawalsEdit',
    component: employeesWithdrawalsForm
  },

  {
    path: 'employeesWithdrawals/:id',
    name: 'employeesWithdrawalsShow',
    component: employeesWithdrawalsShow
  }
]

export default routes
