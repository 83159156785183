var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('reports.accounts.reports'),"description":_vm.$t('reports.accounts.remainingBalanceOfCurrentAccounts')}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" "+_vm._s(_vm.$t('reports.accounts.reportOptions'))+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('reports.accounts.account'),"plaseholder":_vm.$t('reports.accounts.chooseAccount'),"show":'name',"refrance":'id',"value":_vm.params.account_id,"values":_vm.accounts},model:{value:(_vm.params.account_id),callback:function ($$v) {_vm.$set(_vm.params, "account_id", $$v)},expression:"params.account_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('reports.overview.sectionAccount'),"plaseholder":_vm.$t('reports.overview.chooseSection'),"show":'name',"refrance":'id',"value":_vm.params.sections_account_id,"values":_vm.$database.sectionsAccounts},model:{value:(_vm.params.sections_account_id),callback:function ($$v) {_vm.$set(_vm.params, "sections_account_id", $$v)},expression:"params.sections_account_id"}}),_c('selectInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('reports.accounts.statue'),"plaseholder":_vm.$t('reports.accounts.chooseStatue'),"values":[
                { name: _vm.$t('reports.accounts.debtor'), value: 0 },
                { name: _vm.$t('reports.accounts.creditor'), value: 1 },
                { name: _vm.$t('reports.accounts.stopped'), value: 2 }
              ]},model:{value:(_vm.params.status),callback:function ($$v) {_vm.$set(_vm.params, "status", $$v)},expression:"params.status"}}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":_vm.$t('reports.accounts.remainingBalanceOfCurrentAccounts'),"details":[
            { title: _vm.$t('reports.accounts.accuontName'), value: _vm.accountName },
            {
              title: _vm.$t('reports.overview.sectionAccount'),
              value: _vm.sectionCustomerName
            },
            { title: _vm.$t('reports.accounts.statue'), value: _vm.statusName }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":_vm.$t('reports.accounts.thereNoDataTheMoment'),"emptyTableSubText":_vm.$t('reports.accounts.tryAddingSomeAndTryAgain'),"cloumns":[
            {
              column: 'name',
              link: '/accounts',
              title: _vm.$t('reports.accounts.accuontName'),
              type: 'reportMainLink',
              sort: true
            },
            {
              column: 'balance',
              title: _vm.$t('reports.accounts.balance'),
              type: 'text',
              sort: 'true'
            }
          ],"footer":[
            { colspan: 1, value: _vm.$t('reports.accounts.total') },
            { colspan: 1, value: _vm.balance }
          ]}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }