var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('safes.safes'),"description":_vm.$t('safes.safesDescription'),"explanationPath":_vm.path + '/explaination'}}),_c('indexTable',{attrs:{"searchText":_vm.$t('safes.SearchForASafe'),"emptyTableText":_vm.$t('safes.thereAreNosafes'),"buttonRole":_vm.$checkRole('safes_add'),"localData":true,"filter":{ bank_account: 0 },"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
          { column: 'id', title: 'كود', type: 'mainLink', sort: true },
          {
            column: 'name',
            title: _vm.$t('safes.customerName'),
            type: 'mainLink',
            sort: true
          },
          {
            column: 'balance',
            title: _vm.$t('safes.balance'),
            type: 'text',
            sort: true,
            icon: 'usd-square'
          },
          {
            column: 'stat',
            title: _vm.$t('safes.active'),
            type: 'stat',
            sort: true,
            hideMobile: true
          },
          {
            column: 'options',
            title: _vm.$t('safes.actions'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$checkRole('safes_edit')
              },
              {
                name: 'link',
                role: _vm.$checkRole('safes_transfers_add'),

                title: _vm.$t('safes.TransferFromTheTreasury'),
                icon: 'suitcase',
                link: '/safesTransfers/create?from_safe_id='
              },
              {
                name: 'link',
                role: _vm.$checkRole('safes_deposits_add'),
                title: _vm.$t('safes.DepositToTheSafe'),
                icon: 'donate',
                link: '/safesDeposits/create?safe_id='
              },
              {
                name: 'link',
                role: _vm.$checkRole('safes_withdrawals_add'),
                title: _vm.$t('safes.WithdrawalFromTheTreasury'),
                icon: 'money-bill-alt',
                link: '/safesWithdrawals/create?safe_id='
              },
              {
                name: 'delete',
                role: _vm.$checkRole('safes_delete')
              }
            ]
          }
        ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading')
        }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }