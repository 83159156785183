var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t('components.safes.purchaseChecks'))+" ")]),(_vm.$parent.item.stat == 1 && _vm.$checkRole('sales_payments_add'))?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/purchaseCheques/create?safe_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.safes.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$t('components.safes.searchForACheck'),"withoutCard":true,"emptyTableText":_vm.$t('salesCheques.thereAreNosalesCheques'),"filter":{ safe_id: _vm.$route.params.id },"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
        {
          column: 'code',
          title: _vm.$t('components.safes.Paid'),
          type: 'mainLink',
          sort: true
        },
        {
          column: 'client_type',
          title: _vm.$t('purchaseCheques.supplierName'),
          type: 'clintLink',
          sort: true
        },
        {
          column: 'created_date',
          title: _vm.$t('components.safes.dateRegistration'),
          type: 'text',
          sort: true
        },
        {
          column: 'date',
          title: _vm.$t('salesCheques.paymentDate'),
          type: 'text',
          sort: true
        },
        {
          column: 'description',
          title: _vm.$t('salesCheques.thatsAbout'),
          type: 'text',
          sort: true
        },
        {
          column: 'cost',
          title: _vm.$t('salesCheques.amount'),
          type: 'text',
          sort: true
        },
        {
          column: 'payment_method_id',
          title: _vm.$t('components.safes.paymentMethod'),
          type: 'link',
          to: 'paymentMethod',
          sort: true,
          link: false
        },
        {
          column: 'stat',
          title: _vm.$t('components.safes.theCondition'),
          type: 'status',
          sort: true,
          values: [
            {
              title: _vm.$t('components.safes.pending'),
              value: 0,
              color: 'info'
            },
            {
              title: _vm.$t('components.safes.driven'),
              value: 1,
              color: 'success'
            }
          ]
        },
        {
          column: 'options',
          title: _vm.$t('salesCheques.settings'),
          type: 'options',
          options: [
            { name: 'show' },
            {
              name: 'edit',
              role: _vm.$checkRole('sales_cheques_edit')
            },
            {
              name: 'pay',
              role: _vm.$checkRole('sales_cheques_edit')
            },
            {
              name: 'delete',
              role: _vm.$checkRole('sales_cheques_delete')
            }
          ]
        }
      ],"deleteText":{
        attention: _vm.$t('allerts.Attention'),
        areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
        close: _vm.$t('allerts.close'),
        confirm: _vm.$t('allerts.confirm'),
        loading: _vm.$t('allerts.loading')
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }