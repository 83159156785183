var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t('components.partners.divideTheProfitsOfThePartners'))+" ")]),_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/partnersProfits/create?partner_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.partners.addition'))+" ")])],1),_c('indexTable',{attrs:{"searchText":_vm.$t('components.partners.SearchForAProcess'),"emptyTableText":_vm.$t('components.partners.ThereAreNoProfitSplitsAtTheMoment'),"filter":{ partner_id: _vm.$route.params.id },"emptyTableSubText":_vm.$t('components.partners.TryAddingSomeAndTryAgain'),"withoutCard":true,"cloumns":[
        {
          column: 'code',
          title: _vm.$t('components.partners.reference'),
          type: 'mainLink',
          sort: true
        },
        {
          column: 'safe_id',
          title: _vm.$t('components.partners.Project'),
          type: 'link',
          to: 'project',
          sort: true,
          link: true
        },
        { column: 'date', title: _vm.$t('components.partners.date'), type: 'text', sort: true },
        { column: 'total', title: _vm.$t('components.partners.theAmount'), type: 'text', sort: true },
        {
          column: 'description',
          title: _vm.$t('components.partners.thatAbout'),
          type: 'text',
          sort: true
        },
        {
          column: 'options',
          title: _vm.$t('components.partners.actions'),
          type: 'options',
          options: [
            { name: 'show' },
            {
              name: 'edit',
              role: _vm.$checkRole('partners_profits_edit')
            },
            {
              name: 'delete',
              role: _vm.$checkRole('partners_profits_delete')
            }
          ]
        }
      ],"deleteText":{
        attention: _vm.$t('allerts.Attention'),
        areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
        close: _vm.$t('allerts.close'),
        confirm: _vm.$t('allerts.confirm'),
        loading: _vm.$t('allerts.loading')
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }