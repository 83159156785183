var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":'التقارير',"description":'تقارير المشتريات الى سندات الصرف'}}),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('searchInput',{staticClass:"col-md-3",attrs:{"title":"اسم المورد","plaseholder":"أختر اسم المورد","show":'name',"refrance":'id',"value":_vm.params.supplier_id,"values":_vm.$database.suppliers},model:{value:(_vm.params.supplier_id),callback:function ($$v) {_vm.$set(_vm.params, "supplier_id", $$v)},expression:"params.supplier_id"}}),_c('searchInput',{staticClass:"col-md-3",attrs:{"title":_vm.$t('reports.overview.sectionsupplier'),"plaseholder":_vm.$t('reports.overview.chooseSection'),"show":'name',"refrance":'id',"value":_vm.params.sections_account_id,"values":_vm.$database.sectionsAccounts},model:{value:(_vm.params.sections_account_id),callback:function ($$v) {_vm.$set(_vm.params, "sections_account_id", $$v)},expression:"params.sections_account_id"}}),_c('dateFilter',{staticClass:"col-md-3"}),_c('actionButtons',{staticClass:"col-md-3"})],1)])]),_c('div',{attrs:{"id":"reportShow"}},[_c('reportHeader',{attrs:{"title":"المشتريات الى سندات الصرف","details":[
            { title: 'اسم المورد', value: _vm.supplierName },
            {
              title: _vm.$t('reports.overview.sectionsupplier'),
              value: _vm.sectionCustomerName
            }
          ]}}),_c('reportTable',{attrs:{"emptyTableText":'لايوجد بيانات حاليا',"emptyTableSubText":'جرب اضافة البعض واعد المحاولة',"cloumns":[
            {
              column: 'name',
              link: '/suppliers',
              title: 'اسم المورد',
              type: 'reportMainLink',
              sort: true
            },
            {
              column: 'burchase',
              title: 'المشتريات',
              type: 'text',
              sort: true
            },
            {
              column: 'payments',
              title: 'سندات الصرف',
              type: 'text',
              sort: true
            },
            { column: 'balance', title: 'الرصيد', type: 'text', sort: true }
          ],"footer":[
            { colspan: 1, value: 'المجموع' },
            { colspan: 1, value: _vm.burchase },
            { colspan: 1, value: _vm.payments },
            { colspan: 1, value: _vm.balance }
          ]}})],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_c('i',{staticClass:"fal fa-edit"}),_vm._v(" خيارات التقرير ")])])
}]

export { render, staticRenderFns }